import React from 'react';

const Adminlogin = () => {

    return (
        <div>
            Admin login
        </div>
    );

};

export default Adminlogin;