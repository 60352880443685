import React from "react";
import Order from "./Order";
import Sellorder from "./Sellorder";

const OrderBook = (props) => {

  const digits = props.mypairs[0]?props.mypairs[0].decimal_size:2;
  const buyorders=props.allorders.filter(a => a.side==='BUY').reverse().slice(0,22).sort((a,b) => {return b.price - a.price;});
 // const botbuyorders=props.buybots.length>0?props.buybots:[];
  const botbuyorders = [];
  const sellorders=props.allorders.filter(a => a.side==='SELL').slice(0,22).sort((a,b) => {return b.price - a.price;});
 // const botsellorders=props.sellbots.length>0?props.sellbots:[];
  const botsellorders=[];
  const combinebuy  = [...buyorders, ...botbuyorders];
  const combinesell  = [...sellorders, ...botsellorders];


  return (
    <>
      <div className="order-book mb15">
        <h2 className="heading">{props.messages["exchange.orderbook"]} {props[0]} {props[1]}</h2>
        <table className="table">
          <thead>
            <tr>
              <th>{props.messages["exchange.price"]}({props[1]})</th>
              <th>{props.messages["exchange.amount"]}({props[0]})</th>
              <th>{props.messages["exchange.total"]}({props[1]})</th>
            </tr>
          </thead>
          <tbody  className={"trev"}>
          {combinesell.length > 0 &&
          combinesell.map((order) => (
              <Sellorder arkaplan={"red-bg-20"} digits={digits} renk={"red"} item order={order}/>
          ))}
          </tbody>
               <tbody className="ob-heading">
            <tr>
              <td>
                <span>{props.messages["exchange.lastprice"]}</span>
                0.020367
              </td>
              <td>
                <span>{props[0]}</span>
                148.65
              </td>
              <td className="red">
                <span>{props.messages["exchange.change"]}</span>
                -0.51%
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
          <tr>
            <th>{props.messages["exchange.price"]} ({props[1]})</th>
            <th>{props.messages["exchange.amount"]} ({props[0]})</th>
            <th>{props.messages["exchange.total"]} ({props[1]})</th>
          </tr>
          </thead>
          <tbody>
          {combinebuy.length > 0 &&
          combinebuy.map((order) => (
              <Order  arkaplan={"green-bg-20"} digits={digits} renk={"green"} item key={order._id} order={order}/>
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
export  default OrderBook;
