import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchRows} from "../actions/general";
export default function MarketPairs(props) {
  const dispatch = useDispatch();
function getPairs(a){
  const sorgu= { limit: '30', "query": {"first_currency":a} }
  dispatch(fetchRows("pairs",sorgu,"pairss"));

}
  const allData = useSelector((state) => state.general.extradata);
   return (
          <>
          <div className="market-pairs">
           <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                   <i className="icon ion-md-search"/>
              </span>
            </div>
          <input
          type="text"
          className="form-control"
          placeholder= {props.lng.messages["search"]}
          aria-describedby="inputGroup-sizing-sm"
          />
          </div>
            <div className="tableHeader">
          { props.coinler.length>0 ? (
                props.coinler[0].main_coin_list.map((a, b) => {
                  return (
                      <div className="tableTitle" key={b}>
                        <div onClick={() => getPairs(a)}>{a}</div>
                      </div>
                  )
                })) : <></>
          }
            </div>
          <table className="table">
            <thead>
            <tr>
              <th>{props.lng.messages["exchange.pairs"]}</th>
              <th>{props.lng.messages["exchange.price"]}</th>
              <th>{props.lng.messages["exchange.change"]}</th>
            </tr>
            </thead>
            <tbody>
            { allData.pairss ? (
                allData.pairss.map((a) => {
                  return (
            <tr key={a._id}>
              <td>
                  <a className="darklink" href={'/exchange/'+a.first_currency+'_'+a.second_currency}>  <i className="icon ion-md-star"/> {a.title}</a>
              </td>
              <td>0.00020255</td>
              <td className="red">-2.58%</td>
            </tr>
                  )
                })) : <></>
            }
            </tbody>
          </table>
          </div>

          </>
   )
}
