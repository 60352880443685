import React from 'react';
import Anasayfa from "../modules/anasayfa";
export default function Home (currentAppLocale) {

        return (
            <div className="anasayfa">
               <Anasayfa {...currentAppLocale} />
            </div>
        );

}

