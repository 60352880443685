import React from 'react';
import { Link } from 'react-router-dom';

export default function MarketNews(props) {
  return (
    <>
      <div className="market-news mt15">
        <h2 className="heading">{props.messages["exchange.market_news"]}</h2>
        <ul>
          <li>
            <Link to="/news-details">
              <strong> {props.messages["exchange.sample_news_title"]}</strong>
              {props.messages["exchange.sample_news"]}
              <span>2019-12-04 20:22</span>
            </Link>
          </li>
          <li>
            <Link to="/news-details">
              <strong> {props.messages["exchange.sample_news_title"]}</strong>
              {props.messages["exchange.sample_news"]}
              <span>2019-12-04 20:22</span>
            </Link>
          </li>
          <li>
            <Link to="/news-details">
              <strong> {props.messages["exchange.sample_news_title"]}</strong>
              {props.messages["exchange.sample_news"]}
              <span>2019-12-04 20:22</span>
            </Link>
          </li>
          <li>
            <Link to="/news-details">
              <strong> {props.messages["exchange.sample_news_title"]}</strong>
              {props.messages["exchange.sample_news"]}
              <span>2019-12-04 20:22</span>
            </Link>
          </li>

        </ul>
      </div>
    </>
  );
}
