import React from 'react';
import { Link } from 'react-router-dom';
import Header from "../components/Header";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {connect, useDispatch, useSelector} from "react-redux";
import {Snackbar} from "@material-ui/core";
import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
const newsSchema = yup.object().shape({

});

  const Signup = (lng) => {

  const [alertstate, setAlertState] = React.useState({
    open:false,
    alertclass: 'success',
    alertmessage: 'alertmessage'
  });
  const { open , alertmessage, alertclass } = alertstate;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertState({open:false});
  };
  const {register, reset, handleSubmit } = useForm({
    mode: "onBlur",
    resolver: yupResolver(newsSchema),
  });
    const clearForm = () => {
      reset();
    };
  const onSubmit = (data) => {
    axios.post(
        WEB_CONFIG.server+'/api/user/create',
        data,
        { headers: { 'Content-Type': 'application/json' }}
        )
        .then(response =>{
          if(response.data) {
            setAlertState(alertstate => ({...alertstate, alertclass: response.data.status?response.data.status:null , alertmessage:response.data.message?response.data.message:null, open: true}));
          }
          else {
            setAlertState(alertstate => ({...alertstate, alertclass: 'warning' , alertmessage:'Kayıt işlemi sırasında bir problem oluştu. Lütfen tekrar deneyin.', open: true}));
          }

        });


  };

  return (
    <>
          <Header {...lng}  />
      <Snackbar
          onClose={handleClose}
          className={alertclass}
          anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
          open={open}
          autoHideDuration={4000}
          message={alertmessage}
          key={"bottomright"}
      />
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <form method="POST" onSubmit={handleSubmit(onSubmit)}>
            <span>{lng.messages["topnav.apps.create_account"]}</span>
            <div className="form-group">
              <input
                  type="text"
                  {...register("firstName")}
                  className="form-control"
                  placeholder={lng.messages["first_name"]}
                  required
              />
            </div>
            <div className="form-group">
              <input
                  type="text"
                  {...register("lastName")}
                  className="form-control"
                  placeholder={lng.messages["last_name"]}
                  required
              />
            </div>
            <div className="form-group">
              <input
                  type="text"
                  {...register("email")}
                  className="form-control"
                  placeholder="Email"
                  required
              />
            </div>
            <div className="form-group">
              <input
                  type="password"
                  {...register("password")}
                  className="form-control"
                  placeholder={lng.messages["topnav.apps.password"]}
                  required
              />
            </div>
            <div className="form-group">
              <input
                  type="text"
                  {...register("phone")}
                  className="form-control"
                  placeholder={lng.messages["phone"]}
                  required
              />
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="form-checkbox"
                required
              />
              <label className="custom-control-label" htmlFor="form-checkbox">
                I agree to the{' '}
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </label>
            </div>
            <button  onClick={() => handleSubmit(onSubmit)} className="btn btn-primary">
              Create Account
            </button>
          </form>
          <h2>
            Already have an account?
            <Link to="/login"> Sign in here</Link>
          </h2>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = ({ theme }) => {

  return theme;
};
export default connect(mapStateToProps, {  })(Signup);
