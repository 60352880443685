import * as api from "../api/userwallets";
import * as types from "./types";

export const fetchUserwallets = () => async (dispatch) => {
    try {
        const { data } = await api.fetchUserwallets();
        dispatch({
            type: types.FETCH_POSTS,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};

export const fetchSingleUserwallets = (id) => async (dispatch) => {
    try {
        const { data } = await api.fetchSingleUserwallets(id);
        dispatch({
            type: types.FETCH_SINGLE_POST,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};

export const createUserwallets = (userwallets) => async (dispatch) => {
    try {
        const { data } = await api.createUserwallets(userwallets);
        dispatch({
            type: types.CREATE_POST,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};

export const updateUserwallets = (id, userwallets) => async (dispatch) => {
    try {
        const { data } = await api.updateUserwallets(id, userwallets);

        dispatch({ type: types.UPDATE_POST, payload: data });
    } catch (error) {
        console.log(error.message);
    }
};

export const deleteUserwallets = (id) => async (dispatch) => {
    try {
        const { data } = await api.deleteUserwallets(id);
        dispatch({
            type: types.DELETE_POST,
            payload: data._id,
        });
    } catch (error) {
        console.log(error);
    }
};
