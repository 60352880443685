import * as api from "../api/coins";
import * as types from "./types";

export const fetchCoins = () => async (dispatch) => {
    try {
        const { data } = await api.fetchCoins();
        dispatch({
            type: types.FETCH_POSTS,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};

export const fetchSingleCoin = (id) => async (dispatch) => {
    try {
        const { data } = await api.fetchSingleCoin(id);
        dispatch({
            type: types.FETCH_SINGLE_POST,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};

export const createCoin = (coin) => async (dispatch) => {
    try {
        const { data } = await api.createCoin(coin);
        dispatch({
            type: types.CREATE_POST,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};

export const updateCoin = (id, coin) => async (dispatch) => {
    try {
        const { data } = await api.updateCoin(id, coin);

        dispatch({ type: types.UPDATE_POST, payload: data });
    } catch (error) {
        console.log(error.message);
    }
};

export const deleteCoin = (id) => async (dispatch) => {
    try {
        const { data } = await api.deleteCoin(id);
        dispatch({
            type: types.DELETE_POST,
            payload: data._id,
        });
    } catch (error) {
        console.log(error);
    }
};
