import React from "react";
import {Badge, NavDropdown} from 'react-bootstrap';
import { Link } from "react-router-dom";
import navigationConfig from "./NavigationConfig";
import { connect } from "react-redux";
import AppLocale from "../lang";
import {ThemeConsumer} from "../context/ThemeContext";

const SideNavContent = (props) => {
        const {locale } = props;
        const currentAppLocale = AppLocale[locale];
    return (
        <>


            <Link className="menu-img" to="/">
                <ThemeConsumer>
                    {({ data }) => {
                        return data.theme === 'dark' ? (
                            <img src={process.env.PUBLIC_URL +'/img/tiles_white.png'} alt="logo" />
                        ) : (
                            <img src={process.env.PUBLIC_URL +'/img/tiles_black.png'} alt="logo" />
                        );
                    }}
                </ThemeConsumer>
            </Link>

            {navigationConfig.map((menu) =>
                //eğer ana link altında öğe varsa
                menu.submenu.length > 0 ? (
                    <NavDropdown
                        className="subcont2"
                        key={menu.key}
                        title={currentAppLocale.messages[menu.title]}
                    >
                        {menu.submenu.map((subMenuFirst) =>
                            //eğer alt link altında öğe varsa
                                subMenuFirst.submenu.length > 0 ? (
                                    <NavDropdown
                                        className="dropdown-item subcont1"
                                        key={subMenuFirst.key}
                                        title={currentAppLocale.messages[subMenuFirst.title]}>
                                        {subMenuFirst.submenu.map((subMenuSecond) => (
                                            <Link
                                                to={subMenuSecond.path}
                                                className="dropdown-item subsec"
                                                key={subMenuSecond.key}>
                                                <span>
                        {currentAppLocale.messages[subMenuSecond.title]}
                      </span>
                                            </Link>
                                        ))}
                                    </NavDropdown>
                                ) : ( //eğer alt link altında öğe yoksa
                                    <Link
                                        className="nav-link subfirst"
                                        key={subMenuFirst.key}
                                        to={subMenuFirst.path}
                                    >
                                        <span>{currentAppLocale.messages[subMenuFirst.title]}</span>
                                    </Link>
                                )
                        )}
                    </NavDropdown>
                ) : (
                    <Link
                        className="nav-link "
                        to={menu.path}
                        key={menu.key}
                    >
                        <span> {currentAppLocale.messages[menu.title]} <Badge className="bg-warning">{menu.icon?menu.icon: ''}</Badge> </span>

                    </Link>
                )
            )}
        </>
    );
};
const MenuContent = (props) => {
    return (
        <SideNavContent {...props} />
    );
};
const mapStateToProps = ({ theme }) => {
    const {locale } = theme;
    return {locale};
};
export default connect(mapStateToProps, {  })(MenuContent);
