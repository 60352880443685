import React from 'react';

const Adminwallets = () => {

    return (
        <div>
             Adminwallets
        </div>
    );

};

export default Adminwallets;