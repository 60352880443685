import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchPosts } from "../actions/post";
import { makeStyles } from "@material-ui/core/styles";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import {
    CssBaseline,
    Container,
    Grid,
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/MenuBook";
import PenIcon from "@material-ui/icons/Create";
import PostsList from "../components/PostsList";
import AddPostForm from "../components/AddPostForm";
import PostDetails from "../components/PostDetails";
import Header from "../components/Header";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        marginTop: theme.spacing(3),
    },
}));

const Posts = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    useEffect(() => {
        dispatch(fetchPosts());
    }, [dispatch]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();
    return (
        <>
            <Header/>
            <CssBaseline />
            <Container maxWidth="lg">
                <AppBar position="static" color="inherit" elevation={0}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuIcon />
                        </IconButton>

                        <Typography
                            variant="h6"
                            color="secondary"
                            className={classes.title}
                        >

                        </Typography>

                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<PenIcon />}
                            onClick={handleOpen}
                        >
                            Yeni Yazı
                        </Button>
                    </Toolbar>
                </AppBar>
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                        <Router>
                            <Switch>
                                <Route exact path="/posts" component={PostsList} />
                                <Route exact path="/posts/:id" component={PostDetails} />
                            </Switch>
                            <Redirect from="/" to="/posts" />
                        </Router>
                    </Grid>
                </Grid>

                <AddPostForm open={open} handleClose={handleClose} />
            </Container>
        </>
    );
};

export default Posts;
