import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
const olustur ="olustur";
const guncelle ="guncelle/";
const sil ="sil/";
const nodeserver=WEB_CONFIG.server+"/api/userwallets/";

axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

export const fetchUserwallets = async () => await axios.get(nodeserver);

export const fetchSingleUserwallets = async (id) =>
    await axios.get(`${nodeserver}${id}`);

export const createUserwallets = async (userwallets) => await axios.post(`${nodeserver}${olustur}`, userwallets);

export const updateUserwallets = async (id, updatedUserwallets) =>
    await axios.post(`${nodeserver}${guncelle}${id}`, updatedUserwallets);
export const deleteUserwallets = async (id) =>
    await axios.get(`${nodeserver}${sil}${id}`);
