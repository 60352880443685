import React from 'react';
import {
    Route,
    Switch
} from "react-router-dom";
import Adminlogin from "./login";
import Adminwallets from "./wallets";


const Admin = () => {
    //const { id } = useParams();
    require('../../src/assets/css/css/lite-style-1.min.css');
    return (
        <Switch>

            <Route exact path="/admin/login">
                <Adminlogin />
            </Route>
            <Route exact path="/admin/wallets">
                <Adminwallets />
            </Route>

        </Switch>
    );


}

export default Admin;
