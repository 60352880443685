import React from "react";

const Bank = (props) => {
    return (
    <div className={"bank"}>
     <div className={"btitle"}>{props.bank.title}</div>
     <div>IBAN: {props.bank.iban}</div>
     <div>Hesap Sahibi: {props.bank.name}</div>
    </div>

    );
}
export  default Bank;