import React from 'react';
import { useCookies } from 'react-cookie';
import jwtDecode from "jwt-decode";

function Cook() {

   const enc = Buffer.from(window.navigator.appVersion, 'utf8').toString('base64');
    const [cookies] = useCookies();
    if(localStorage.getItem("gsdhhrxchbhlkpkakd")) {
    const jdc = jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));

    if(cookies[jdc.cjwtpto]!==enc)  {
        localStorage.clear();
        //console.log("cookie yok");
    }

    }




    return (
        <></>
    );
}

export default Cook;
