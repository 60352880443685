import React, {useEffect, useRef, useState} from 'react';
import socketIOClient from "socket.io-client";
import HistoryOrder from '../components/HistoryOrder';
import MarketHistory from '../components/MarketHistory';
import MarketNews from '../components/MarketNews';
import MarketPairs from '../components/MarketPairs';
import MarketTrade from '../components/MarketTrade';
import OrderBook from "../components/OrderBook";
import TradingChartDark from '../components/TradingChartDark';
import { ThemeConsumer } from '../context/ThemeContext';
import Header from "../components/Header";
import {connect, useDispatch, useSelector} from "react-redux";
import {fetchRows,fetchOrders} from "../actions/general";
import { useParams } from 'react-router-dom';
import ExchangeTop from "../components/ExchangeTop";
import NewsTicker from "../components/NewsTicker";
import jwtDecode from "jwt-decode";
import {WEB_CONFIG} from "../config/Static";
import TradeChart from "../components/TradeChart";
import TradingChart from "../components/TradingChart";

function TradingChartLight() {
    return null;
}

const Exchange = (lng) => {
  const { market } = useParams();
  const symbols = market.split("_")
  const dispatch = useDispatch();
  function getuserlocal() {    if(localStorage.getItem("gsdhhrxchbhlkpkakd"))
  {    return jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd")).id    }
  else {     return null;    }  }
  const userid= getuserlocal();
  const [durum, setDurum] = useState(false);
  const [fullorders, setFullorders] = useState(false);
  const [socketacik, setSocketacik] = useState(true);
  const balancesorgu = {limit:'2', "query": {"user": userid, $or: [{"currency_symbol": symbols[0]}, {"currency_symbol": symbols[1]}] } }
  const ordersorgu= { limit: '44', "query": {"status": "NEW", "symbol": market} }
  const myordersorgu= { limit: '0', "query": {type:"WEB","status": "NEW", "symbol": market, "user": userid} }
  const mygecmissorgu= { limit: '30', "query": {type:"WEB", "symbol": market, "user": userid} }
  const buyselsorgu= { limit: '30', "query": {botside:"BUYSELL",type:"BOT", "symbol": market, "user": userid} }

    const sellbuysorgu= { limit: '0', "query": {botside:"SELLBUY",type:"BOT", "symbol": market, "user": userid, $or: [{'status': 'NEW'}, {'status': 'CANCELED'}]   } }

    const buysellordersorgu= { limit: '6', "query": {side:"BUYSELL", type:"BOT","status": "NEW", "symbol": market} }
  const sellbuyordersorgu= { limit: '6', "query": {side:"SELLBUY", type:"BOT","status": "NEW", "symbol": market} }
  const filledsorgu= { limit: '30', "query": {"status": "FILLED", "symbol": market, amount: { $gt: 0 } }}
  const sorgu= { limit: '1', aktif:1 }
  const firstsorgu = { limit: '0', "query": {"first_currency": symbols[0]} }
  const mypairsorgu = { limit: '1', "query": {"first_currency": symbols[0],"second_currency": symbols[1]} }
  const mysorgu= { limit: '1', "query": { userid:userid, market:market, side: 'BUYSELL'}}
  const [connected, setConnected] = useState(null);

 async function updatedata() {

      dispatch(fetchRows("orders",myordersorgu,"myorders"))
      dispatch(fetchRows("orders",mygecmissorgu,"mygecmis"))
      dispatch(fetchRows("userbots",mysorgu,"botsorgu"));
      dispatch(fetchRows("pairs",firstsorgu,'pairss'));
      dispatch(fetchRows("pairs",mypairsorgu,'mypair'));
      dispatch(fetchRows("marketsettings",sorgu,"marketsettings"));
      dispatch(fetchRows("currencies",sorgu,"currencies"));
      //dispatch(fetchOrders("orders",buysellordersorgu,"buybots"));
      //dispatch(fetchOrders("orders",sellbuyordersorgu,"sellbots"));
      dispatch(fetchRows("orders",filledsorgu,"filled"));
     // dispatch(fetchRows("orders",buyselsorgu,"buysellorders"));
      dispatch(fetchRows("orders",sellbuysorgu,"sellbuyorders"));
      dispatch(fetchRows("balances",balancesorgu,"mybalances"));
      dispatch(fetchRows("orders",ordersorgu,"allorders"))
      setFullorders(true);


  }

  useEffect(() => {
    if(durum===false){
      updatedata().then(r => setDurum(true));
    }
  }, [durum]);



     function updatesocketdata(socket) {
          dispatch(fetchRows("orders", myordersorgu, "myorders"));
          dispatch(fetchRows("orders", ordersorgu, "allorders"));
        //  dispatch(fetchRows("orders",buyselsorgu,"buysellorders"));
          dispatch(fetchRows("orders",sellbuysorgu,"sellbuyorders"));
         dispatch(fetchRows("balances",balancesorgu,"mybalances"));
         dispatch(fetchRows("orders",mygecmissorgu,"mygecmis"))
           socket.disconnect()
           socket.connect()

    }


    useEffect(() => {
        const socket = socketIOClient(WEB_CONFIG.socketserver);
        socket.on("ordersocket", data => {
            if(data==='orderkontrol') {
                    updatesocketdata(socket);
            }
        });
    }, []);

  const allData = useSelector((state) => state.general);
  const marketsets = allData.extradata.marketsettings?allData.extradata.marketsettings:[];
  console.log("allData"); console.log(allData);
  const allorders = allData.extradata.allorders;
//  const buybots = allData.extradata.buybots;
  //const sellbots = allData.extradata.sellbots;
  const myorders = allData.extradata.myorders;
  const mygecmis = allData.extradata.mygecmis;
  const mypairs = allData.extradata.mypair;


  return (
      <>
        <Header {...lng}  />
        <NewsTicker {...lng} {...symbols} />

        <div className="container-fluid  no-fluid">
          <div className="row sm-gutters">
            <div className="col-sm-12 col-md-9">
              <div className="row sm-gutters">
                <div className="col-sm-12 col-md-12">
                  <ExchangeTop {...lng} {...symbols} />
                </div>

                <div className="col-sm-12 col-md-4">
                  <MarketPairs coinler={marketsets} lng={lng} />
                  <MarketHistory filled={allData.extradata.filled?allData.extradata.filled:[]} {...lng} {...symbols} />
                </div>
                <div className="col-sm-12 col-md-8">
                  <ThemeConsumer>
                    {({ data }) => {
                      return data.theme === 'light' ? (
                          <TradingChart />

                      ) : (
                          <TradingChartDark />
                      );
                    }}
                  </ThemeConsumer>
                  <MarketTrade userid={userid}  allorders={allorders?allorders:[]} {...lng} {...symbols} />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <OrderBook mypairs={mypairs?mypairs:[]} sellbots={[]} buybots={[]}  allorders={allorders?allorders:[]}  {...lng} {...symbols} />
            </div>
            <div className="col-md-3">
              <MarketNews {...lng} {...symbols} />
            </div>
            <div className="col-md-9">
              <HistoryOrder mygecmis={mygecmis?mygecmis:[]} myorders={myorders?myorders:[]} {...lng} {...symbols} />
            </div>
          </div>
        </div>
      </>
  );
}
const mapStateToProps = ({ theme }) => {
  return theme;
};
export default connect(mapStateToProps, {  })(Exchange);
