import React, {useEffect, useState} from 'react';
import { Tabs, Tab} from 'react-bootstrap';
import {createBotOrders} from "../actions/order";
import {connect, useDispatch, useSelector } from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReactTooltip from 'react-tooltip';
import jwtDecode from "jwt-decode";
import {botSorgu, fetchRows} from "../actions/general";
import {useParams} from "react-router-dom";
const  MarketTrade = (props) => {
    const newsSchema = yup.object().shape({
        // amount: yup.number().required().positive()
    });
    const dispatch = useDispatch();
    const {register, handleSubmit, reset, setValue, getValues  } = useForm({
        mode: "onBlur",
        resolver: yupResolver(newsSchema),
    });
    const {register: register2,handleSubmit:handleSubmit2, reset:reset2, setValue:setValue2, getValues:getValues2 } = useForm({
        mode: "onBlur",
        resolver: yupResolver(newsSchema),
    });

    const {register: register3,handleSubmit:handleSubmit3, setValue:setValue3, getValues:getValues3 } = useForm({
        mode: "onBlur",
        resolver: yupResolver(newsSchema),
    });
    const {register: register4,handleSubmit:handleSubmit4, setValue:setValue4, getValues:getValues4 } = useForm({
        mode: "onBlur",
        resolver: yupResolver(newsSchema),
    });
    const allData = useSelector((state) => state.general);
    const digits=4;
    //const digits = allData.extradata.mypair?allData.extradata.mypair[0].decimal_size:4;
    const digitplus = parseInt(digits)+3;
    const botdata = useSelector((state) => state.general.extradata);
    function getBotemri () {
        if(botdata.alisemri) {
            let buyprice = botdata.alisemri.buyprice;
            let sellprice = botdata.alisemri.price?botdata.alisemri.price:botdata.alisemri.sellprice;
            let amount = botdata.alisemri.amount?botdata.alisemri.amount:0;
            let total = botdata.alisemri.buytotal;

            setValue3('buyprice',  parseFloat(buyprice).toFixed(digitplus));   setValue3('sellprice', sellprice); setValue3('amount', amount);
            setValue4('buyprice',  parseFloat(buyprice).toFixed(digitplus));   setValue4('sellprice', sellprice); setValue4('amount', amount);
            setValue2('price', parseFloat(buyprice).toFixed(digitplus));   setValue2('amount', amount); setValue2('total', total);
            let komisyon = parseFloat(amount/1000).toFixed(5)
            setFormdata(formdata => ({...formdata, satiscomission:komisyon }));
        }
        if(botdata.satisemri) {


            let buyprice = botdata.satisemri.buyprice;
            let sellprice = botdata.satisemri.sellprice;


            let amount = botdata.satisemri.amount?botdata.satisemri.amount:0;
            let total = botdata.satisemri.selltotal;
            setValue('price', parseFloat(sellprice).toFixed(digitplus));   setValue('amount', amount); setValue('total', total);
            setValue4('buyprice', parseFloat(buyprice).toFixed(digitplus));   setValue4('sellprice', parseFloat(sellprice).toFixed(digitplus)); setValue4('amount', amount);
            let komisyon = parseFloat(total/1000).toFixed(5)
            setFormdata(formdata => ({...formdata, aliscomission:komisyon }));
        }



    }
    useEffect(() => {
        getBotemri();


    }, [botdata]);
    function getuserlocal() {
        if(localStorage.getItem("gsdhhrxchbhlkpkakd"))
        {
            return jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd")).id
        }
        else {
            return null;
        }
    }
    const { market } = useParams();
    const symbols = market.split("_")
    const mybalances = allData.extradata.mybalances;
    const comission = allData.extradata.marketsettings?allData.extradata.marketsettings[0].comission:0;

    const fb=mybalances?mybalances.filter(a => a.currency_symbol===props[1])[0]:[];
    const buyorders=props.allorders.filter(a => a.side==='BUY').slice(0,1).sort((a,b) => {return b.price - a.price;});
    const sellorders=props.allorders.filter(a => a.side==='SELL').reverse().slice(0,1).sort((a,b) => {return b.price - a.price;});
    const userid= getuserlocal();
    const botsorgu = allData.extradata.botsorgu?allData.extradata.botsorgu:[];
    const sellbotsorgu = allData.extradata.sellbotsorgu?allData.extradata.sellbotsorgu:[];
    const sb=mybalances?mybalances.filter(a => a.currency_symbol===props[0])[0]:[];

    const buyselsorgu= { limit: '0', "query": { "symbol": market, "user": userid} }
    const ordersorgu= { limit: '44', "query": {"status": "NEW", "symbol": market} }
    const myordersorgu= { limit: '0', "query": {type:"WEB","status": "NEW", "symbol": market, "user": userid} }
    const buyselsorguall= { limit: '30', "query": {botside:"BUYSELL",type:"BOT", "symbol": market, "user": userid} }
    const sellbuysorgu= { limit: '0', "query": {botside:"SELLBUY",type:"BOT", "symbol": market, "user": userid} }
    const balancesorgu = {limit:'2', "query": {"user": userid, $or: [{"currency_symbol": symbols[0]}, {"currency_symbol": symbols[1]}] } }

    const [buyerror,setBuyerror] = useState({
        "title": "Emir başarısız. Yetersiz bakiye.",
        "disable":true,
        "buyclass":"",
    });
    const [buysellerror,setBuysellerror] = useState({
        "title": "Emir başarısız. Yetersiz bakiye.",
        "disable":true,
        "buyclass":"",
    });

    const [sellerror,setSellerror] = useState({
        "title": "Emir başarısız. Yetersiz bakiye.",
        "disable":true,
        "sellclass":"",
    });
    const [sellbuyerror,setSellbuyerror] = useState({
        "title": "Emir başarısız. Yetersiz bakiye.",
        "disable":true,
        "sellclass":"",
    });
    const [formdata, setFormdata] = useState({
        "alisfiyat":0,
        "aliscomission":0,
        "satiscomission":0,
        "alismiktar":0,
        "alistoplam":0,
        "satisfiyat":0,
        "satismiktar":0,
        "satistoplam":0,
        "alisbakiye":fb?fb.balance:0,
        "satisbakiye":sb?sb.balance:0
    });
    const [botalsat, setBotalsat] = React.useState({
        botid:null,
    });
    const [botsatal, setBotsatal] = React.useState({
        botid:null,
    });


    useEffect(() => {

        if(buyorders.length>0) {
            setValue('price', buyorders[0].price);
            setFormdata(formdata => ({...formdata, alisfiyat: buyorders[0].price}));
        }
        if(sellorders.length>0) {
            setValue2('price', sellorders[0].price);
            setFormdata(formdata => ({...formdata, satisfiyat: sellorders[0].price}));
        }

    }, []);
    const onSubmit = (buy) => {
        if(buy.total<10)
        {
            setBuyerror(buyerror => ({...buyerror, disable: false, title: "Alış Fiyatınız 10 Tl ve  üzeri olmalıdır", buyclass: " redborder"}));
        }
        else {
            if(userid){
                let comtotal=parseFloat(formdata.aliscomission)+parseFloat(buy.total);
                let comamount = (parseFloat(buy.amount)*comission)+parseFloat(buy.amount);
                let  buyprice = parseFloat(buy.price).toFixed(digitplus);
                let dhd= new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
                const sonveri = ({...buy,type:'WEB',comtotal:comtotal,buyprice:buyprice,buytotal:parseFloat(buy.total),buycomtotal:comtotal,comamount:comamount,comission:formdata.aliscomission,user:userid,side:"BUY",symbol:props[0]+"_"+props[1],status:"NEW", date: dhd});
              //  console.log(sonveri);
                if(comtotal>parseFloat(fb.balance)){
                    setBuyerror(buyerror => ({...buyerror, disable: false, title: "Bakiyeniz Yetersiz", buyclass: " redborder"}));
                }
                else {
                    if(sonveri.price<=0) {
                        setBuyerror(buyerror => ({...buyerror, disable: false, title: "Lütfen Fiyat Giriniz", buyclass: " redborder"}));
                    }
                    else if(sonveri.amount<=0) {
                        setBuyerror(buyerror => ({...buyerror, disable: false, title: "Lütfen Geçerli Bir Miktar Giriniz", buyclass: " redborder"}));
                    }
                    else {
                        dispatch(createBotOrders({ ...sonveri}));
                        dispatch(fetchRows("balances",balancesorgu,"mybalances"));
                        setBuyerror(buyerror => ({...buyerror, disable: true, title: "", buyclass: " "}));
                    }
                    clearForm();
                } }
            else {
                window.location.reload();
            }
        } };
    const onSubmitSale = (sale) => {
        if(sale.total<10)

        {

            setSellerror(sellerror => ({...sellerror, disable: false, title: "Satış Toplamınız 10 TL ve  üzeri olmalıdır", sellclass: " redborder"}));
        }
        else {


            let comtotal = parseFloat(formdata.satiscomission)+parseFloat(sale.total);
            let comamount = parseFloat(sale.amount)+parseFloat(formdata.satiscomission);
            let  sellprice = parseFloat(sale.price).toFixed(digitplus);
            let dhd= new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
            const salesonveri = ({...sale,total:sale.total,type:'WEB',sellprice:sellprice,selltotal:parseFloat(sale.total),sellcomtotal:comtotal,comtotal:comtotal,comamount:comamount,comission:formdata.satiscomission,user:userid,side:"SELL",symbol:props[0]+"_"+props[1],status:"NEW", date:dhd});
           // console.log(salesonveri);
            if(comamount>sb.balance){
                let yetersiz = "Bakiyeniz Yetersiz, Gereken Miktar:"+(comamount)+props[0];
                setSellerror(sellerror => ({...sellerror, disable: false, title: yetersiz, sellclass: " redborder"}));
            }
            else {
                if(salesonveri.sellprice<=0) {
                    setSellerror(sellerror => ({...sellerror, disable: false, title: "Lütfen Fiyat Giriniz", sellclass: " redborder"}));
                }
                else if(salesonveri.amount<=0) {
                    setSellerror(sellerror => ({...sellerror, disable: false, title: "Lütfen Geçerli Bir Miktar Giriniz", sellclass: " redborder"}));
                }
                else {
                    dispatch(createBotOrders({ ...salesonveri}));
                    dispatch(fetchRows("balances",balancesorgu,"mybalances"));
                    setSellerror(sellerror => ({...sellerror, disable: true, title: "", sellclass: " "}));
                    clearForm2();
                }
                //dispatch(createOrders({ ...sale}));
                //clearForm2();
            } }
    }
    const getBots = () => {
        if(userid) {
            getBotsatal();
            const mysorgu= { limit: '1', "query": { userid:userid, market:market, side: 'BUYSELL'}}
            dispatch(fetchRows("userbots",mysorgu,"botsorgu")).then((e => {
                if(botsorgu.length>0) {
                    const botsor = botsorgu[0];
                    setBotalsat(botalsat => ({...botalsat, botid: botsor._id}));
                }
                else {

                }
            }));
        }
        else {
            setBotalsat([]);
        }
    }
    const getBotsatal = () => {
        if(userid) {
            const mysorgu= { limit: '1', "query": { userid:userid, market:market, side: 'SELLBUY'}}
            dispatch(fetchRows("userbots",mysorgu,"sellbotsorgu")).then((e => {
                if(sellbotsorgu) {
                    if(sellbotsorgu.length>0) {
                        const botsor = sellbotsorgu[0];
                        setBotsatal(botsatal => ({...botsatal, botid: botsor._id}));
                    }
                }
            }));
        }
        else {
            setBotsatal([]);
        }
    }
    const onSubmitBuySale = (buysell) => {
        let buytotal = parseFloat(buysell.buyprice) * parseFloat(buysell.amount);
        let selltotal = parseFloat(buysell.sellprice) * parseFloat(buysell.amount);
        let buyprice = parseFloat(buysell.buyprice).toFixed(digitplus);
        let sellprice = parseFloat(buysell.sellprice).toFixed(digitplus);
        if(+buyprice >= +sellprice)
        {
            alert("Dikkat! Satış fiyatınız alış fiyatınızdan daha düşük olamaz. ");
        }
        else
        {

            if(buytotal<10 && selltotal<10)

            {

                alert("Alış ve Satış Toplamlarınız 10 TL ve  üzeri olmalıdır. Alış Toplaınız:"+buytotal+" Satış Toplamınız:"+selltotal);
            }
            else {
                if (botalsat.botid) {
                    if(buytotal>fb.balance){

                        let yetersiz = props[1]+" Bakiyeniz Yetersiz, Gereken Miktar:"+(buytotal)+props[1];
                        setBuysellerror(buysellerror => ({...buysellerror, disable: false, title: yetersiz, buyclass: " redborder"}));
                    }
                    else if (selltotal>sb.balance) {
                        let yetersiz = props[0]+" Bakiyeniz Yetersiz, Gereken Miktar:"+(selltotal)+props[0];
                        setBuysellerror(buysellerror => ({...buysellerror, disable: false, title: yetersiz, buyclass: " redborder"}));
                    }
                    else {



                        buysell.amount = parseFloat(buysell.amount);
                        buysell.botid = botalsat.botid
                        let buytotal = parseFloat(buysell.buyprice) * parseFloat(buysell.amount);
                        let selltotal = parseFloat(buysell.sellprice) * parseFloat(buysell.amount);
                        let buycomamount = parseFloat(buytotal) * comission;
                        let buycomtotal = buytotal + buycomamount;
                        let sellcomamount = parseFloat(selltotal) * comission;
                        let sellcomtotal = buytotal + sellcomamount;
                        let buysellcomamounttotal = parseFloat(buysell.amount + buycomamount);
                        let comamount = parseFloat(buysell.amount + buycomamount);
                        let dhd= new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
                        const buysellesonveri = ({
                            ...buysell,
                            sellprice: sellprice,
                            buyprice: buyprice,
                            botid: botalsat.botid,
                            comamount: comamount,
                            comission: buycomamount,
                            type: 'BOT',
                            firstamount: buysell.amount,
                            firstbuytotal: buytotal,
                            firstselltotal: selltotal,
                            buytotal: buytotal,
                            selltotal: selltotal,
                            buycomamount: buycomamount,
                            sellcomamount: sellcomamount,
                            buycomtotal: buycomtotal,
                            sellcomtotal: sellcomtotal,
                            botside: 'BUYSELL',
                            user: userid,
                            side: "BUY",
                            symbol: props[0] + "_" + props[1],
                            status: "NEW",
                            date: dhd
                        });

                        dispatch(createBotOrders({...buysellesonveri})).then((e => {
                            dispatch(fetchRows("buysellorders", buyselsorgu, "buysellorders"));
                            dispatch(fetchRows("orders", ordersorgu, "allorders"));
                            dispatch(fetchRows("orders", myordersorgu, "myorders"));
                            dispatch(fetchRows("orders", buyselsorguall, "buysellorders"));
                            dispatch(fetchRows("orders", sellbuysorgu, "sellbuyorders"));
                        }));
                    }
                } else {
                    alert("Emirlerinizi eklemek için lütfen önce botunuzu bir kez açıp kapayınız. " +
                        "Bu uyarı sadece ilk kez emir verecekseniz geçerlidir. Bir kez bot açıldıktan sonra " +
                        "emirlerinizi bot kapalıyken verebilirsiniz.")
                }
            }

        }
    }
    const onSubmitSellbuy = (sellbuy) => {
      // console.log(sellbuy);
        let buytotal = parseFloat(sellbuy.buyprice)*parseFloat(sellbuy.amount);
        let selltotal = parseFloat(sellbuy.sellprice)*parseFloat(sellbuy.amount);
        let  buyprice = parseFloat(sellbuy.buyprice).toFixed(digitplus);
        let  sellprice = parseFloat(sellbuy.sellprice).toFixed(digitplus);
        if(+buyprice >= +sellprice)
        {
            alert("Dikkat! Satış fiyatınız alış fiyatınızdan daha düşük olamaz. "+buyprice+" "+sellprice);
        }
        else
        {

            if(buytotal<10 && selltotal<10)

            {

                alert("Alış ve Satış Toplamlarınız 10 TL ve  üzeri olmalıdır. Alış Toplaınız:"+buytotal+" Satış Toplamınız:"+selltotal);
            }
            else {
                if(botsatal.botid) {
                    if(buytotal>sb.balance){

                        let yetersiz = props[0]+" Bakiyeniz Yetersiz, Gereken Miktar: "+(buytotal)+props[0];
                        setSellbuyerror(sellbuyerror => ({...sellbuyerror, disable: false, title: yetersiz, sellclass: " redborder"}));
                    }
                    else if(selltotal>fb.balance){
                        let yetersiz = props[1]+" Bakiyeniz Yetersiz, Gereken Miktar: "+(selltotal)+props[1];
                        setSellbuyerror(sellbuyerror => ({...sellbuyerror, disable: false, title: yetersiz, sellclass: " redborder"}));
                    }
                    else {

                        sellbuy.buyprice = parseFloat(sellbuy.buyprice);
                        sellbuy.sellprice = parseFloat(sellbuy.sellprice);
                        sellbuy.amount = parseFloat(sellbuy.amount);
                        sellbuy.botid=botalsat.botid
                        let buycomamount = parseFloat(buytotal)+parseFloat(formdata.aliscomission);
                        let buycomtotal = buytotal+buycomamount;

                        let selcom = comission*parseFloat(sellbuy.amount)

                        let sellcomamount = parseFloat(sellbuy.amount)+parseFloat(selcom);
                        let sellcomtotal = buytotal+sellcomamount;
                        let sellbuycomamounttotal = parseFloat(sellbuy.amount+buycomamount);
                        let comamount = parseFloat(sellbuy.amount+buycomamount);

                        let dhd= new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
                        const sellbuyesonveri = ({...sellbuy,buyprice:buyprice,sellprice:sellprice,botid:botsatal.botid,type:'BOT',comamount:comamount,comission:formdata.satiscomission,firstamount:sellbuy.amount,buysellcomamounttotal:sellbuycomamounttotal,firstbuytotal:buytotal,firstselltotal:selltotal,buytotal:buytotal,selltotal:selltotal,buycomamount:buycomamount,sellcomamount:sellcomamount,buycomtotal:buycomtotal,sellcomtotal:sellcomtotal, botside:'SELLBUY',user:userid,side:"SELL",symbol:props[0]+"_"+props[1],status:"NEW", date:dhd});
                       // console.log(sellbuyesonveri);

                      dispatch(createBotOrders({ ...sellbuyesonveri})).then((e => {
                            dispatch(fetchRows("balances",balancesorgu,"mybalances"));
                        }));
                    }
                } else { alert("Emirlerinizi eklemek için lütfen önce botu aktif hale getiriniz") } }
        }
    }
    const clearForm = () => {
        reset();
    };
    const clearForm2 = () => {
        reset2();
    };
    function alisFiyat(e,b) {
        let key = e.nativeEvent.data;
        //let keyCharCode = key.charCodeAt(0);
        if(key===',') {
            setValue('price', 0);
            setValue('amount', 0);
            setValue('total', 0);
        }
        else {
            let alistoplam = (parseFloat(e.target.value)*parseFloat(b.amount)).toFixed(digits);
            let soncomission = parseFloat(alistoplam*comission).toFixed(digits);
            setFormdata(formdata => ({...formdata, alisfiyat: e.target.value, alistoplam: alistoplam, aliscomission: soncomission}));
            setValue('total', alistoplam?alistoplam:0);

        }


    }
    function alisMiktar(e,b) {
        let key = e.nativeEvent.data;
        if(key===',') {
            setValue('price', 0);
            setValue('amount', 0);
            setValue('total', 0);
        }
        else {
            let alistoplam = (e.target.value * b.price).toFixed(digits);
            let soncomission = (alistoplam * comission).toFixed(digits);
            setFormdata(formdata => ({
                ...formdata,
                alismiktar: e.target.value,
                alistoplam: alistoplam,
                aliscomission: soncomission
            }));
            setValue('total', alistoplam ? alistoplam : 0);
        }
    }
    function alisToplam(e,b) {
        let key = e.nativeEvent.data;
        if(key===',') {
            setValue('price', 0);
            setValue('amount', 0);
            setValue('total', 0);
        }
        else {
            let alismiktar = e.target.value / b.price;
            let soncomission = (e.target.value * comission).toFixed(digits);

            setFormdata(formdata => ({
                ...formdata,
                alismiktar: alismiktar,
                alistoplam: e.target.value,
                aliscomission: soncomission
            }));
            setValue('amount', alismiktar ? alismiktar : 0);
        }
    }
    function satisFiyat(e,b) {

        let key = e.nativeEvent.data;
        if(key===',') {
            setValue2('price', 0);
            setValue2('amount', 0);
            setValue2('total', 0);
        }
        else {
            let satistoplam = (e.target.value*b.amount).toFixed(digits);
            let soncomission = (b.amount * comission).toFixed(digits);
            setFormdata(formdata => ({
                ...formdata,
                satisfiyat: e.target.value,
                satistoplam: satistoplam,
                satiscomission: soncomission
            }));
            setValue2('total', satistoplam ? satistoplam : 0);
        }
    }
    function satisMiktar(e,b) {

        let key = e.nativeEvent.data;
        if(key===',') {
            setValue2('price', 0);
            setValue2('amount', 0);
            setValue2('total', 0);
        }
        else {
            let satistoplam = (e.target.value*b.price).toFixed(digits);
            let soncomission = (e.target.value * comission).toFixed(digits);
            setFormdata(formdata => ({
                ...formdata,
                satismiktar: e.target.value,
                satistoplam: satistoplam,
                satiscomission: soncomission
            }));
            setValue2('total', satistoplam ? satistoplam : digits);
        }
    }
    function satisToplam(e,b) {
        let key = e.nativeEvent.data;
        if(key===',') {
            setValue2('price', 0);
            setValue2('amount', 0);
            setValue2('total', 0);
        }
        else {
            let satismiktar = e.target.value / b.price;
            let soncomission = (b.amount * comission).toFixed(digits);

            setFormdata(formdata => ({
                ...formdata,
                satismiktar: satismiktar,
                satistoplam: e.target.value,
                satiscomission: soncomission
            }));
            setValue2('amount', satismiktar);
        }
    }
    function alsatMiktar(e,b) {

        let key = e.nativeEvent.data;
        if(key===',') {
            setValue('price', 0);
            setValue('amount', 0);
            setValue('total', 0);
        }
        else {
            let alistoplam = (e.target.value * b.buyprice).toFixed(digits);
            let satistoplam = (e.target.value * b.sellprice).toFixed(digits);
            let aliscomission = (alistoplam * comission).toFixed(digits);
            let satiscomission = (satistoplam * comission).toFixed(digits);
            setFormdata(formdata => ({
                ...formdata,
                alismiktar: e.target.value,
                satismiktar: e.target.value,
                alistoplam: alistoplam,
                satistoplam: satistoplam,
                aliscomission: aliscomission,
                satiscomission: satiscomission
            }));
            setValue3('total', alistoplam ? alistoplam : 0);
        }
    }
    function satalMiktar(e,b) {
        let key = e.nativeEvent.data;
        if(key===',') {
            setValue('price', 0);
            setValue('amount', 0);
            setValue('total', 0);
        }
        else {
            let alistoplam = (e.target.value * b.buyprice).toFixed(digits);
            let satistoplam = (e.target.value * b.sellprice).toFixed(digits);
            let aliscomission = (alistoplam * comission).toFixed(digits);
            let soncomission = (e.target.value * comission).toFixed(digits);
            let satiscomission = (satistoplam * comission).toFixed(digits);
            setFormdata(formdata => ({
                ...formdata,
                alismiktar: e.target.value,
                satismiktar: e.target.value,
                alistoplam: alistoplam,
                satistoplam: satistoplam,
                aliscomission: aliscomission,
                satiscomission: satiscomission
            }));
            setValue4('total', satistoplam ? satistoplam : 0);
        }
    }
    function addAmount(e,amount){
        e.preventDefault();
        let myb=fb?fb.balance:0
        const sonamount = ((myb/100)*amount)-parseFloat(formdata.aliscomission);
        setValue('total', sonamount);
        let soncomission = (sonamount*comission).toFixed(digits);
        setFormdata(formdata => ({...formdata, aliscomission: soncomission}));
        const sonmiktar = (sonamount/formdata.alisfiyat).toFixed(digits);
        setValue('amount', sonmiktar);
    }
    function addAmount2(e,amount){
        e.preventDefault();
        let myb=sb?sb.balance:0
        const sonamount = (((myb/100)*amount)-parseFloat(formdata.satiscomission)).toFixed(digits);

        setValue2('total', sonamount);
        let soncomission = (sonamount*comission).toFixed(digits);
        setFormdata(formdata => ({...formdata, satiscomission: soncomission}));
        if(formdata.satisfiyat>0) {
            const sonmiktar = (sonamount/formdata.satisfiyat).toFixed(digits);
            setValue2('amount', sonmiktar);
        }
    }
    const handleBotsorgu = (bot) => {
        //Bu durumda bot durdurulacak
        const botsorgusu = ({...bot,userid:userid,market:market,side:"BUYSELL",action: "Başlat",status:"danger",actionicon: "play",actionstatus: "success", title: "Bot Durduruldu"});
        dispatch(botSorgu("userbots",botsorgusu,"botsorgu")).then((e => {
            const mysorgu= { limit: '1', "query": { userid:userid, market:market, side: 'BUYSELL'}}
            dispatch(fetchRows("userbots",mysorgu,"botsorgu")).then((e => {
                setBotalsat(botalsat => ({...botalsat, action: botsorgusu.action,actionstatus:botsorgusu.actionstatus, status:botsorgusu.status, title:botsorgusu.title,actionicon:botsorgusu.actionicon}));
                window.location.reload();
            }));
        }));
    }
    const handleSellBotsorgu = (bot) => {
        const userid= getuserlocal();


        //Bu durumda bot durdurulacak

        const sellbotsorgusu = ({...bot,userid:userid,market:market,side:"SELLBUY",action: "Başlat",status:"danger",actionicon: "play",actionstatus: "success", title: "Bot Durduruldu"});



        dispatch(botSorgu("userbots",sellbotsorgusu,"sellbotsorgu")).then((e => {
            const mysorgu= { limit: '1', "query": { userid:userid, market:market, side: 'SELLBUY'}}
            dispatch(fetchRows("userbots",mysorgu,"sellbotsorgu")).then((e => {
                setBotsatal(botsatal => ({...botsatal, action: sellbotsorgusu.action,actionstatus:sellbotsorgusu.actionstatus, status:sellbotsorgusu.status, title:sellbotsorgusu.title,actionicon:sellbotsorgusu.actionicon}));
                window.location.reload();
            }));

        }));


    }
    return (
        <>
            <div className="market-trade">
                <Tabs as={"Button"}  defaultActiveKey="limit" onSelect={() =>getBots()  } >
                    <Tab  tabClassName={"tab-btn"}  eventKey="limit" title={props.messages["exchange.limitorder"]}>
                        <div className="dd-flex justify-content-between">
                            <div className="market-trade-buy">
                                <form key='buyform' method="POST" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{props.messages["exchange.price"]}</span>
                                        </div>
                                        <input
                                            {...register("price")}
                                            onChange={(e) => {alisFiyat(e,getValues())}}
                                            defaultValue={formdata.alisfiyat}
                                            type="text"
                                            autoComplete={"off"}
                                            maxLength={digitplus}
                                            className="form-control"
                                            placeholder={props.messages["exchange.price"]}
                                            required
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">{props[1]}</span>
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{props.messages["exchange.amount"]}</span>
                                        </div>
                                        <input
                                            type="text"
                                            {...register("amount")}
                                            defaultValue={0}
                                            autoComplete={"off"}
                                            onChange={(e) => {alisMiktar(e,getValues())}}
                                            className="form-control"
                                            maxLength={digitplus}
                                            placeholder={props.messages["exchange.amount"]}
                                            required
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">{props[0]}</span>
                                        </div>
                                    </div>
                                    <ul className="market-trade-list">
                                        <li>
                                            <a  onClick={(e) => {addAmount(e,25)}} href="/#">25%</a>
                                        </li>
                                        <li>
                                            <a onClick={(e) => {addAmount(e,50)}} href="/#">50%</a>
                                        </li>
                                        <li>
                                            <a onClick={(e) => {addAmount(e,75)}} href="/#">75%</a>
                                        </li>
                                        <li>
                                            <a onClick={(e) => {addAmount(e,100)}} href="/#">100%</a>
                                        </li>
                                    </ul>

                                    <ReactTooltip disable={buyerror.disable} id="buyerror" type="error">
                                        <span>{buyerror.title}</span>
                                    </ReactTooltip>
                                    <div data-tip data-for="buyerror" className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{props.messages["exchange.total"]}</span>
                                        </div>
                                        <input
                                            type="text"
                                            {...register("total")}
                                            onChange={(e) => {alisToplam(e,getValues())}}
                                            className={"form-control "+buyerror.buyclass}
                                            autoComplete={"off"}
                                            placeholder={props.messages["exchange.total"]}
                                            required
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">{props[1]}</span>
                                        </div>
                                    </div>
                                    <p>
                                        {props.messages["exchange.available"]} : <span>{fb?fb.balance:0}  {props[1]}</span>
                                    </p>
                                    <p>
                                        {props.messages["exchange.commission"]}: <span>{formdata.aliscomission} {props[1]}</span>
                                    </p>
                                    {props.userid ? (
                                            <button type={"submit"} onClick={() => handleSubmit(onSubmit)} className="btn buy">
                                                {props[0]}  {props.messages["exchange.buy"]}
                                            </button>
                                        ) :
                                        <button type="button"  className="btn buy">
                                          {props.messages["exchange.login_first"]}
                                        </button>
                                    }
                                </form>
                            </div>
                            <div className="market-trade-sell">
                                <form key='sellform' method="POST" onSubmit={handleSubmit2(onSubmitSale)}>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{props.messages["exchange.price"]}</span>
                                        </div>
                                        <input
                                            type="text"
                                            {...register2("price")}
                                            onChange={(e) => {satisFiyat(e,getValues2())}}
                                            className="form-control"
                                            maxLength={digitplus}
                                            autoComplete={"off"}
                                            placeholder={props.messages["exchange.price"]}
                                            required
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">{props[1]}</span>
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{props.messages["exchange.amount"]}</span>
                                        </div>
                                        <input
                                            type="number"
                                            {...register2("amount")}
                                            defaultValue={0}
                                            maxLength={digitplus}
                                            onChange={(e) => {satisMiktar(e,getValues2())}}
                                            className="form-control"
                                            autoComplete={"off"}
                                            placeholder={props.messages["exchange.amount"]}
                                            required
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">{props[0]}</span>
                                        </div>
                                    </div>
                                    <ul className="market-trade-list">
                                        <li>
                                            <a  onClick={(e) => {addAmount2(e,25)}} href="/#">25%</a>
                                        </li>
                                        <li>
                                            <a onClick={(e) => {addAmount2(e,50)}} href="/#">50%</a>
                                        </li>
                                        <li>
                                            <a onClick={(e) => {addAmount2(e,75)}} href="/#">75%</a>
                                        </li>
                                        <li>
                                            <a onClick={(e) => {addAmount2(e,100)}} href="/#">100%</a>
                                        </li>
                                    </ul>



                                    <ReactTooltip disable={sellerror.disable} id="sellerror" type="error">
                                        <span>{sellerror.title}</span>
                                    </ReactTooltip>
                                    <div data-tip data-for="sellerror" className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{props.messages["exchange.total"]}</span>
                                        </div>
                                        <input
                                            type="text"
                                            autoComplete={"off"}
                                            {...register2("total")}
                                            onChange={(e) => {satisToplam(e,getValues2())}}
                                            className={"form-control "+sellerror.sellclass}
                                            placeholder={props.messages["exchange.total"]}
                                            required
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">{props[1]}</span>
                                        </div>
                                    </div>
                                    <p>
                                        {props.messages["exchange.available"]}: <span>{sb?sb.balance:0} {props[0]}</span>
                                    </p>
                                    <p>
                                        {props.messages["exchange.commission"]}: <span>{formdata.satiscomission} {props[0]}</span>
                                    </p>
                                    {props.userid ? (
                                            <button type={"submit"}  onClick={() => handleSubmit2(onSubmitSale)} className="btn sell">
                                                {props[0]} {props.messages["exchange.sell"]}</button>
                                        ):
                                        <button type="button"  className="btn sell">
                                            {props.messages["exchange.login_first"]}
                                        </button>
                                    }
                                </form>
                            </div>
                        </div>
                    </Tab>
                    <Tab tabClassName={"tab-btn"}  eventKey="stop-limit" title={props.messages["exchange.botorder_sellbuy"]}>
                        <div className="dd-flex justify-content-between">

                            <div className="market-trade-sell">

                                {botsatal.botid ? (
                                    <form key='sellform' method="POST" onSubmit={handleSubmit4(onSubmitSellbuy)}>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Satış Fiyatı</span>
                                            </div>
                                            <input
                                                {...register4("sellprice")}
                                                onChange={(e) => {satisFiyat(e,getValues4())}}
                                                defaultValue={formdata.satisfiyat}
                                                type="text"
                                                maxLength={digitplus}
                                                autoComplete={"off"}
                                                className=" form-control"
                                                placeholder={props.messages["exchange.price"]}
                                                required
                                            />
                                            <div className="input-group-append mr-2">
                                                <span className="input-group-text">{props[1]}</span>
                                            </div>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"> {props.messages["exchange.buyprice"]}</span>
                                            </div>
                                            <input
                                                {...register4("buyprice")}
                                                onChange={(e) => {alisFiyat(e,getValues4())}}
                                                type="text"
                                                autoComplete={"off"}
                                                maxLength={digitplus}
                                                className="form-control"
                                                placeholder={props.messages["exchange.buyprice"]}
                                                required
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{props[1]}</span>
                                            </div>
                                        </div>
                                        <ReactTooltip disable={sellbuyerror.disable} id="sellbuyerror" type="error">
                                            <span>{sellbuyerror.title}</span>
                                        </ReactTooltip>
                                        <div  data-tip data-for="sellbuyerror" className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">{props.messages["exchange.amount"]}</span>
                                            </div>
                                            <input
                                                type="text"
                                                {...register4("amount")}
                                                defaultValue={0}
                                                autoComplete={"off"}
                                                onChange={(e) => {satalMiktar(e,getValues4())}}
                                                className={"form-control "+sellbuyerror.sellclass}
                                                placeholder={props.messages["exchange.amount"]}
                                                required
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{props[0]}</span>
                                            </div>
                                        </div>
                                        <p>
                                            {props.messages["exchange.selltotal"]}: <span>{formdata.satistoplam} {props[1]}</span>
                                        </p>
                                        <p>
                                            {props.messages["exchange.buytotal"]}: <span>{formdata.alistoplam} {props[1]}</span>
                                        </p>

                                        {allData.userdata.id ? (
                                                <button type={"submit"} onClick={() => handleSubmit3(onSubmitSellbuy)} className="btn sell">
                                                    {props[0]}   SAT - AL Emri Oluştur
                                                </button>
                                            ):
                                            <button type="button"  className="btn buy">
                                            {props.messages["exchange.login_first"]}
                                            </button>
                                        }
                                    </form>
                                ):(
                                    <div className={"botuyari"}>
                                        To enter bot orders, first create a Sell bot
                                        <br></br>
                                        <button onClick={() => handleSellBotsorgu(botsatal)} className={"btn_olustur btn btn-success"}><i className="icon ion-md-play-circle" /> Yeni Bot Oluştur</button>
                                    </div>
                                ) }
                            </div>

                        </div>
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}
const mapStateToProps = ({ theme }) => {
    return theme;
};
export default connect(mapStateToProps, {  })(MarketTrade);
