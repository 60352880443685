import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
const olustur ="olustur";
const guncelle ="guncelle/";
const sil ="sil/";

const nodeserver=WEB_CONFIG.server+"/api/coins/";

axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

export const fetchCoins = async () => await axios.get(nodeserver);

export const fetchSingleCoin = async (id) =>
    await axios.get(`${nodeserver}${id}`);

export const createCoin = async (coin) => await axios.post(`${nodeserver}${olustur}`, coin);

export const updateCoin = async (id, updatedCoin) =>
    await axios.post(`${nodeserver}${guncelle}${id}`, updatedCoin);
export const deleteCoin = async (id) =>
    await axios.get(`${nodeserver}${sil}${id}`);
