import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
export default function MarketHistory(props) {
  return (
    <>
      <div className="market-history">
        <Tabs defaultActiveKey="recent-trades">
          <Tab eventKey="recent-trades" title={props.messages["exchange.recent_trades"]}>
            <table className="table">
              <thead>
                <tr>
                  <th>{props.messages["exchange.time"]}</th>
                  <th>{props.messages["exchange.price"]}({props[0]})</th>
                  <th>{props.messages["exchange.amount"]}({props[1]})</th>
                </tr>
              </thead>
              <tbody>
              {props.filled.length > 0 &&
              props.filled.map((order) => (
                <tr key={order._id}>
                  <td>{order.date}</td>
                  <td className={"red side_"+order.side}>{order.price}</td>
                  <td>{order.amount}</td>
                  <td>{order.total}</td>
                </tr>
              ))}

              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
