import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
const apiEndpoint =WEB_CONFIG.server+"/api/orders/";
const generalEndpoint =WEB_CONFIG.server+"/api/general/";
const createorder ="createorder";
const createbotorder ="createbotorder";
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
export const fetchOrders = async () => await axios.get(apiEndpoint);

export const fetchOrdersByField = async (market,side) => await axios.get(`${apiEndpoint}field/?side=${side}&symbol=${market}`);
export const fetchSingleOrder = async (id) =>
    await axios.get(`${apiEndpoint}${id}`);

export const createOrders = async (veri) => await axios.post(`${generalEndpoint}${createorder}`, veri);
export const createBotOrders = async (veri) => await axios.post(`${generalEndpoint}${createbotorder}`, veri);
export const updateOrder = async (id, updatedOrder) =>
    await axios.patch(`${apiEndpoint}${id}`, updatedOrder);

export const deleteOrder = async (id) =>
    await axios.delete(`${apiEndpoint}${id}`);
