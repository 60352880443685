import React from 'react';
import Layout from '../components/Layout';
import { Switch, Route  } from 'react-router-dom';
import Exchange from '../pages/exchange';
import Markets from '../pages/markets';
import Post from './posts';
import Profile from './profile';
import Wallet from './wallet';
import Bankaodeme from './bankaodeme';
import Settings from './settings';
import Coins from './coins';
import News from './news';
import Login from './login';
import Reset from './reset';
import OtpVerify from './otp-verify';
import OtpNumber from './otp-number';
import Lock from './lock';
import TermsAndConditions from './terms-and-conditions';
import NewsDetails from './news-details';
import Signup from './signup';
import Notfound from './notfound';
import Userwallets from './userwallets';
import Home from "./home";
import Admin from "../admin";
import Adminindex from "../admin/src";
import Logout from "./logout";
import PrivateRoute from "../components/PrivateRoute";
import {connect} from "react-redux";
import AppLocale from "../lang";
const Index = (props) => {
  const {locale } = props;
  const currentAppLocale = AppLocale[locale];

  return (
    <>
      <Layout>
        <Switch>

          <Route exact path="/">
            <Home {...currentAppLocale} />
          </Route>
          <Route exact  path="/admin/:id" >
            <Admin />
          </Route>
          <Route exact  path="/admin" >
            <Adminindex />
          </Route>

          <Route path="/markets">
            <Markets {...currentAppLocale} />
          </Route>
          <Route path="/posts">
            <Post />
          </Route>
          <Route path="/userwallets">
            <Userwallets />
          </Route>
          <Route  path="/exchange/:market">
            <Exchange {...currentAppLocale} />
          </Route>
          <Route path="/coins">
            <Coins {...currentAppLocale} />
          </Route>
          <Route path="/bankaodeme">
            <Bankaodeme {...currentAppLocale} />
          </Route>
          <Route path="/news">
            <News {...currentAppLocale} />
          </Route>
          <PrivateRoute path="/profile">
          <Profile {...currentAppLocale} />
         </PrivateRoute>
          <PrivateRoute path="/wallet" component={Wallet} {...currentAppLocale} />
          <PrivateRoute path="/settings" component={Settings} {...currentAppLocale} />
          <Route path="/login">
            <Login {...currentAppLocale} />
          </Route>
          <Route path="/logout">
            <Logout {...currentAppLocale} />
          </Route>
          <Route path="/signup">
            <Signup {...currentAppLocale} />
          </Route>
          <Route path="/reset">
            <Reset {...currentAppLocale} />
          </Route>
          <PrivateRoute path="/otp-verify" component={OtpVerify} />
          <PrivateRoute path="/otp-number" component={OtpNumber} />

          <Route path="/lock">
            <Lock />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/news-details">
            <NewsDetails />
          </Route>
          <Route path="/notfound">
            <Notfound />
          </Route>
        </Switch>
      </Layout>
    </>
  );
}
const mapStateToProps = ({ theme }) => {
  const {locale } = theme;
  return {locale};
};

export default connect(mapStateToProps, {  })(Index);
