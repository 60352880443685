import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
const olustur ="olustur";
const guncelle ="guncelle/";
const sil ="sil/";
const nodeserver=WEB_CONFIG.server+"/api/posts/";
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
export const fetchPosts = async () => await axios.get(nodeserver);
export const fetchSinglePost = async (id) =>
    await axios.get(`${nodeserver}${id}`);
export const createPost = async (post) => await axios.post(`${nodeserver}${olustur}`, post);
export const updatePost = async (id, updatedPost) =>
    await axios.post(`${nodeserver}${guncelle}${id}`, updatedPost);
export const deletePost = async (id) =>
    await axios.get(`${nodeserver}${sil}${id}`);
