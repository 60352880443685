const NewsTicker = (props) => {
  console.log("props");   console.log(props);
    return(
        <div className="news_ticker row md-gutters">
            <div className="col-sm-12 col-md-4">
                <div className="news_title"><a href="/">{props.messages["ticker1"]} </a></div>
            </div>
            <div className="col-sm-12 col-md-4">
                <div className="news_title"> <a href="/">{props.messages["ticker2"]}</a></div>
            </div>
            <div className="col-sm-12 col-md-4">
                <div className="news_title"><a href="/">{props.messages["ticker3"]}</a></div>
            </div>
        </div>


    );

}
export default NewsTicker;
