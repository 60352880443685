import React from 'react';
import ReactDOM from 'react-dom';
import store from "./store";
import App from './App';
import { Provider } from "react-redux";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/css/ionicons.min.css';
import './assets/scss/style.scss';

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);