import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import Header from "../components/Header";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
import {setAuthorizationToken} from "../helpers/setAuthorizationToken";
import { useCookies } from 'react-cookie';
import jwtDecode from "jwt-decode";

const newsSchema = yup.object().shape({ });

export default function Login(lng) {
  const [cookies,setCookie] = useCookies();

  let history = useHistory();
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState("none");

  const {register, handleSubmit } = useForm({
    mode: "onBlur",
    resolver: yupResolver(newsSchema),
  });
//Auth() === true ? console.log("Onaylı"):console.log("Onaysız");

  const onSubmit = data => {

    axios.post(
        WEB_CONFIG.server+'/api/user/login',
        data,
        { headers: { 'Content-Type': 'application/json' }}
         )
        .then(response =>{
          setMessage(response.data.message)
          if(response.data.success === true) {
            setStatus('alert alert-success block');
            let tok = jwtDecode(response.data.token);
            localStorage.setItem("gsdhhrxchbhlkpkakd", response.data.token);
            setAuthorizationToken(response.data.token);
            const enc = Buffer.from(window.navigator.appVersion, 'utf8').toString('base64');
            if(cookies) {}
            setCookie(tok.cjwtpto, enc, {
            path : '/',
            sameSite:true
            });

            history.push('/');
          }
          else {
            setStatus('alert alert-danger block');
          }

        })
        .catch(error => {
         // console.log(error.data)
        });
  };






  return (
    <>
        <Header {...lng}  />
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <div className={status} role="alert">
            {message}
          </div>
          <form method="POST" onSubmit={handleSubmit(onSubmit)} >

            <span>{lng.messages["topnav.apps.giris"]} </span>
            <div className="form-group">
              <input
                  type="text"
                  {...register("email")}
                  className="form-control"
                  placeholder="Email"
                  required
              />
            </div>
            <div className="form-group">
              <input
                  autoComplete="off"
                  type="password"
                  {...register("password")}
                  className="form-control"
                  placeholder={lng.messages["topnav.apps.sifre"]}
                  required
              />
            </div>
            <div className="text-right">
              <Link to="/reset">Forgot Password?</Link>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="form-checkbox"
              />
              <label className="custom-control-label" htmlFor="form-checkbox">
                Remember me
              </label>
            </div>
            <button onClick={() => handleSubmit(onSubmit)} className="btn btn-primary">
              Sign In
            </button>
          </form>
          <h2>
            Don't have an account? <Link to="/signup">Sign up here</Link>
          </h2>
        </div>
      </div>
    </>
  );
}
