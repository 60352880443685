const finansNavTree = [

    {
        key: 'home',
        path: `/`,
        title: 'home',
        icon: '',
        breadcrumb: true,
        submenu: []
    },
    {
        key: 'buysel',
        path: `/exchange/TRX_TRY`,
        title: 'topnav.apps.buysale',
        icon: '',
        breadcrumb: true,
        submenu: []
    },
    {
        key: 'market',
        path: `/markets`,
        title: 'topnav.apps.market',
        icon: '',
        breadcrumb: true,
        submenu: []
    },
    {
        key: 'earn',
        path: `#`,
        title: 'topnav.apps.earn',
        icon: '',
        breadcrumb: true,
        submenu: []
    }
    ,
  
    {
        key: 'academy',
        path: `#`,
        title: 'topnav.apps.academy',
        icon: 'Başla',
        breadcrumb: true,
        submenu: []
    },
    {
    key: 'diger',
    path: `#`,
    title: 'topnav.apps.diger',
    breadcrumb: true,
    submenu: [
        {
            key: 'giris',
            path: `/login`,
            title: 'topnav.apps.giris',
            icon: '',
            breadcrumb: true,
            submenu: []
        },
        {
            key: 'bankaodeme',
            path: `/bankaodeme`,
            title: 'bankaodeme',
            icon: '',
            breadcrumb: true,
            submenu: []
        }
    ]
}]

const navigationConfig = [

    ...finansNavTree,

]

export default navigationConfig;
