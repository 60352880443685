import React, { useState } from "react";
import FileBase64 from "react-file-base64";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updatePost } from "../actions/post";

const useStyles = makeStyles((theme) => ({
    textField: {
        marginBottom: theme.spacing(2),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
}));

const tags = ["fun", "programming", "health", "science"];

const postSchema = yup.object().shape({

    tag: yup.mixed().oneOf(tags),
});

const EditPostForm = ({ post, closeEditMode }) => {
    const dispatch = useDispatch();

    const [file, setFile] = useState(post?.image);
    const { register, handleSubmit,reset } = useForm({
        resolver: yupResolver(postSchema),
    });

    const onSubmit = (data) => {
        const updatedPost = {
            _id: post._id,
            ...data,
            image: file,
        };
        dispatch(updatePost(post._id, updatedPost));

        reset();
        setFile(null);
        closeEditMode();
    };

    const classes = useStyles();
    return (
        <div>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <input  className={classes.textField} defaultValue={post?.title} size="small"  id="content" {...register("title")}  />
                <input  className={classes.textField} defaultValue={post?.subtitle} size="small"  id="content" {...register("subtitle")}  />
                <select {...register("tag")}>
                    <option defaultValue={post?.tag}>{post?.tag}</option>
                    {tags.map((tag, index) => (
                        <option key={index} value={tag}>{tag}</option>
                    ))}
                </select>

                <input  className={classes.textField} defaultValue={post?.content} size="small"  id="content" {...register("content")}  />


                <FileBase64 multiple={false} onDone={({ base64 }) => setFile(base64)} />
                <div className={classes.buttons}>
                    <Button color="primary"  onClick={closeEditMode}>
                        Vazgeç
                    </Button>{" "}

                    <Button color="secondary"  type="submit">
                        Kaydet
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default EditPostForm;