import React from 'react';
import { Link } from 'react-router-dom';
import Header from "../components/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Anasayfa(lng) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    require ('../assets/scss/anasayfa/anasayfa.scss');
        return (

            <div>


                   <Header {...lng} />



                <div className="fixed-menu">
                    <div className="fixed-menu__header">
                        <Link className="logo logo--color mob-sticky-text-logo nt-logo" to="/">
                            <div className="logo__img bg-none nt-img-logo">
                                <img className="static-logo" src={'img/Logo_white.svg'} alt="Logo" width={45} height={51} />
                                <img className="sticky-logo" src={'img/Logog_blue.svg'} alt="Logo" width={45} height={51} /></div>
                            <div className="logo__title nt-text-logo">Bixgate</div>
                        </Link>
                        <div className="btn-close">
                            <svg xmlns="http://www.w3.org/2000/svg"  version="1.1" x="0px" y="0px" viewBox="0 0 47.971 47.971" style={{enableBackground: 'new 0 0 47.971 47.971'}} xmlSpace="preserve" width="512px" height="512px">
                                <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z" fill="#006DF0">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <div className="fixed-menu__content"><ul className="mob-menu"><li className="mob-menu__item"><Link to="/about" title="About" className="mob-menu__link">About</Link></li>
                        <li className="mob-menu__item"><Link to="/services"  className="mob-menu__link">Services</Link></li>
                        <li className="mob-menu__item"><Link to="/timeline"  className="mob-menu__link">Road Map</Link></li>
                        <li className="mob-menu__item"><Link to="/statistic"  className="mob-menu__link">Statistic</Link></li>
                        <li className="mob-menu__item"><Link to="/token"  className="mob-menu__link">Token</Link></li>
                        <li className="mob-menu__item"><Link to="/document"  className="mob-menu__link">WhitePappers</Link></li>
                        <li className="mob-menu__item"><Link to="/team"  className="mob-menu__link">Team</Link></li>
                        <li className="mob-menu__item"><Link to="/faq"  className="mob-menu__link">FAQ</Link></li>
                        <li className="mob-menu__item"><Link to="/blog"  className="mob-menu__link">Blog</Link></li>
                    </ul>
                        <div className="select">
                            <select className="select mob-on select-hidden">
                                <option value="RU" defaultValue="RU">RU</option>
                                <option value="UA">UA</option>
                                <option value="EN">EN</option>
                            </select>
                            <div className="select-styled">RU</div>
                            <ul className="select-options"><li rel="RU">RU</li>
                                <li rel="UA">UA</li>
                                <li rel="EN">EN</li>
                            </ul></div>
                        <div className="btn-wrap mob-on">
                            <Link to="/" className="btn-sign-in">Sign Up</Link></div>
                    </div>
                </div>
                <div className="c-backtop-1 -js-backtop" style={{display: 'none'}}>
                    <i className="icon ion-ios-arrow-up" /></div>
                <div className="wrapper">
                    <div className=" zindex1">
                        <div id="hero"  className="ult-responsive vc_row wpb_row vc_row-fluid vc_custom_1541405532604 bg-bottom-center overflow_visible zindex1 vc_row-has-fill" style={{position: 'relative', boxSizing: 'border-box', width: '100%', }}>
                            <div className="wpb_column vc_column_container vc_col-sm-12" style={{zIndex: 1}}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper ">
                                        <div id="first-screen" className="promo promo4  active">
                    <h1 data-aos="fade-up" data-aos-anchor="#first-screen" className="aos-init aos-animate">{lng.messages["home.enbuyuk"]}
                        <span>{lng.messages["home.kriptoborsasi"]}</span>
                    </h1>
                                            <p data-aos="fade-up" data-aos-anchor="#first-screen" className="aos-init aos-animate">
                                                {lng.messages["home.slideyazi"]}
                                            </p>


                                            <div className="promo__btns-wrap aos-init aos-animate" data-aos="fade-up" data-aos-anchor="#first-screen" data-aos-delay={400}>
                                                <Link className="sld-btn btn btn--red btn--medium" to="/">
                                                    <i className="btn-icon icon ion-ios-card"></i>  {lng.messages["home.exchange"]}</Link>
                                                <Link className="sld-btn btn btn--green btn--big" to="/register"><i className="btn-icon icon ion-md-lock"></i>
                                                    {lng.messages["home.register"]}</Link>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_row-full-width vc_clearfix" />
                    </div>
<div className=" ptb-50 bg-gray">

<div className="mt-20 container">
                    <Slider {...settings}>
                        <div className="slidebox">
                            <div className="row">
                                <div className="col-md-9 coin-alan "><img className="coin-img" src={'img/bitcoin.png'} alt="step-icon-1.png" /> BTC/TRY </div>
                                <div className="col-md-3"><div className="degisim up"> %12.5</div></div>
                                <div className="col-md-6"><div className="slfiyat down">125.756₺</div></div>
                                <div className="col-md-12 center"><img className="slide-img" src={'img/coin.png'} alt="step-icon-1.png" /></div>
                                <div className="col-md-12">
                                    <a className="btn btn-al btn-sat" href="/"><span> {lng.messages["buy"]}  </span></a>
                                </div>
                            </div>
                        </div>
                        <div className="slidebox">
                            <div className="row">
                                <div className="col-md-9 coin-alan "><img className="coin-img" src={'img/usdt.png'} alt="step-icon-1.png" /> USDT/TRY </div>
                                <div className="col-md-3"><div className="degisim up"> %10.8</div></div>
                                <div className="col-md-6"><div className="slfiyat down">25.756₺</div></div>
                                <div className="col-md-12 center"><img className="slide-img" src={'img/coin.png'} alt="step-icon-1.png" /></div>
                                <div className="col-md-12">
                                    <a className="btn btn-al btn-sat" href="/"><span> {lng.messages["buy"]}</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="slidebox">
                            <div className="row">
                                <div className="col-md-9 coin-alan "><img className="coin-img" src={'img/bnb.png'} alt="step-icon-1.png" /> BNB/TRY </div>
                                <div className="col-md-3"><div className="degisim up"> %8.5</div></div>
                                <div className="col-md-6"><div className="slfiyat down">48.756₺</div></div>
                                <div className="col-md-12 center"><img className="slide-img" src={'img/coin.png'} alt="step-icon-1.png" /></div>
                                <div className="col-md-12">
                                    <a className="btn btn-al btn-sat" href="/"><span> {lng.messages["buy"]}</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="slidebox">
                            <div className="row">
                                <div className="col-md-9 coin-alan "><img className="coin-img" src={'img/eth.png'} alt="step-icon-1.png" /> ETH/TRY </div>
                                <div className="col-md-3"><div className="degisim up"> %22.4</div></div>
                                <div className="col-md-6"><div className="slfiyat up">75.756₺</div></div>
                                <div className="col-md-12 center"><img className="slide-img" src={'img/coin.png'} alt="step-icon-1.png" /></div>
                                <div className="col-md-12">
                                    <a className="btn btn-al btn-sat" href="/"><span> {lng.messages["buy"]}</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="slidebox">
                            <div className="row">
                                <div className="col-md-9 coin-alan "><img className="coin-img" src={'img/bitcoin.png'} alt="step-icon-1.png" /> BNB/TRY </div>
                                <div className="col-md-3"><div className="degisim up"> %12.5</div></div>
                                <div className="col-md-6"><div className="slfiyat down">125.756₺</div></div>
                                <div className="col-md-12 center"><img className="slide-img" src={'img/coin.png'} alt="step-icon-1.png" /></div>
                                <div className="col-md-12">
                                    <a className="btn btn-al btn-sat" href="/"><span> {lng.messages["buy"]}</span></a>
                                </div>
                            </div>
                        </div>
                    </Slider>


</div>

</div>
                    <div className="vc_row-full-width vc_clearfix" />




                    <div className="container">
                        <div id="steps"  className="ult-responsive vc_row wpb_row vc_row-fluid vc_custom_1540867352387 overflow_visible vc_row-o-content-middle vc_row-flex" style={{position: 'relative',  boxSizing: 'border-box'}}>
                            <div className="wpb_column vc_column_container vc_col-sm-12" style={{zIndex: 4}}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper "><h4 style={{textAlign: 'center'}} className="ch_119292 vc_custom_heading vc_custom_1541323398600">
                                        {lng.messages["home.register"]}</h4>
                                        <h2 style={{textAlign: 'center'}} className="mb-20 ch_809060 vc_custom_heading vc_custom_1541323410386">
                                            {lng.messages["home.howsystemworks"]}</h2>
                                        <img src={'img/tr-3.png'} style={{top: '-300px', left: '-50%', transform: 'translate3d(0px, 2761.39px, 0px)'}} data-jarallax-element={-200} alt="" className="absolute__bg parallax__img md-off" data-jarallax-original-styles=" top:-300px; left:-50%;" />
                                        <div style={{position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none', zIndex: -100}} id="jarallax-container-2">
                                            <div style={{position: 'fixed'}} />
                                        </div>
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4 vc_col-xs-12" style={{zIndex: 3}}>
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper ">
                                                        <div className="step">
                                                            <div className="step__icon">
                                                                <div className="step__num">1</div>
                                                                <div className="step__num">1</div>
                                                                <img src={'img/step-icon-1.png'} alt="step-icon-1.png" /></div>
                                                            <div className="step__title">{lng.messages["home.steps1title"]}</div>
                                                            <p>
                                                                {lng.messages["home.steps1desc"]}
                                                            </p></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4 vc_col-xs-12" style={{zIndex: 2}}>
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper ">
                                                        <div className="step">
                                                            <div className="step__icon">
                                                                <div className="step__num">2</div>
                                                                <div className="step__num">2</div>
                                                                <img src={'img/step-icon-2.png'} alt="step-icon-2.png" /></div>
                                                            <div className="step__title">{lng.messages["home.steps2title"]}</div>
                                                            <p>
                                                                {lng.messages["home.steps2desc"]}
                                                            </p></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4 vc_col-xs-12" style={{zIndex: 1}}>
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper ">
                                                        <div className="step">
                                                            <div className="step__icon">
                                                                <div className="step__num">3</div>
                                                                <div className="step__num">3</div>
                                                                <img src={'img/step-icon-3.png'} alt="step-icon-3.png" /></div>
                                                            <div className="step__title">{lng.messages["home.steps3title"]}</div>
                                                            <p>   {lng.messages["home.steps3desc"]}</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_row-full-width vc_clearfix" />
                    </div>





                    <div className="row">
                        <div id="team"  className="mb-20 p-30 bg-gray ult-responsive vc_row wpb_row vc_row-fluid vc_custom_1540867414775 bg-left-bottom vc_row-has-fill" style={{position: 'relative',  boxSizing: 'border-box'}}>
                            <div className="wpb_column vc_column_container vc_col-sm-12" style={{zIndex: 4}}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper "><h4 style={{textAlign: 'center'}} className="ch_325646 vc_custom_heading vc_custom_1541323908443">
                                          {lng.messages["home.whytitle"]}
                                    </h4><h2 style={{textAlign: 'center'}} className="mb-20 ch_209566 vc_custom_heading vc_custom_1541323920276">
                                        {lng.messages["home.whydesc"]}
                                    </h2>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4 vc_col-xs-12" style={{zIndex: 3}}>
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper ">
                                        <div className="team-member-style-3 aos-init aos-animate" data-aos="fade-up">
                                            <Link className="team-member" to="" >
                                            <div className="team-member__ava"><img alt="" src={"img/new-img-2-1.jpg"} className="team-member__avatar"  />
                                                <div className="team-member__social-link" style={{backgroundImage: 'url(img/bitcoin.png)'}} />
                                            </div>
                                            <div className="team-member__name">   {lng.messages["home.whytitle1"]}</div>

                                <p className="team-member__desc">
                                    {lng.messages["home.whydesc1"]}
                                </p>
                                        </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4 vc_col-xs-12" style={{zIndex: 2}}>
            <div className="vc_column-inner ">
                <div className="wpb_wrapper ">
                    <div className="team-member-style-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
                        <Link className="team-member" to="" >
                        <div className="team-member__ava"><img alt="" src={'img/new-img-1-1.jpg'} className="team-member__avatar"  />
                            <div className="team-member__social-link" style={{backgroundImage: 'url(img/usdt.png)'}} />
                        </div>
                        <div className="team-member__name">   {lng.messages["home.whytitle2"]}</div>

                        <p className="team-member__desc">
                            {lng.messages["home.whydesc2"]}
                        </p></Link></div>
                </div>
            </div>
</div>
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4 vc_col-xs-12" style={{zIndex: 1}}>
                <div className="vc_column-inner ">
                    <div className="wpb_wrapper ">
                        <div className="team-member-style-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay={400}>
                            <Link className="team-member" to="" >
                            <div className="team-member__ava"><img alt="" src={'img/new-img-3-1.jpg'} className="team-member__avatar" />
                                <div className="team-member__social-link" style={{backgroundImage: 'url(img/bnb.png)'}}>
                                </div>
                            </div>
                            <div className="team-member__name">   {lng.messages["home.whytitle3"]}</div>

        <p className="team-member__desc">{lng.messages["home.whydesc3"]}
        </p></Link></div>
                    </div>
                </div>
            </div>
        </div>
                                        <img src={'img/tr-5.png'} style={{top: '-15%', left: '-50%', transform: 'translate3d(0px, 2887.1px, 0px)'}} data-jarallax-element={-100} alt="" className="absolute__bg parallax__img md-off" data-jarallax-original-styles=" top:-15%; left:-50%;" />
                                        <div style={{position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none', zIndex: -100}} id="jarallax-container-8">
                                            <div style={{position: 'fixed'}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_row-full-width vc_clearfix" />
                    </div>



                    <div className="container">
                        <div id="press_about"  className="bg-white p-30 ult-responsive vc_row wpb_row vc_row-fluid vc_custom_1541324808566 bg-top-center" style={{position: 'relative', boxSizing: 'border-box'}}>
                            <div className="wpb_column vc_column_container vc_col-sm-12" style={{zIndex: 7}}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper "><h4 style={{textAlign: 'center'}}
                                                                      className="ch_283300 vc_custom_heading vc_custom_1541323979096">
                                        {lng.messages["home.advtitle"]}</h4>
                                        <h2 style={{textAlign: 'center'}} className="mb-20 ch_911654 vc_custom_heading vc_custom_1541323988570">
                                            {lng.messages["home.advdesc"]}</h2>
                                        <img src={'img/tr-6.png'} style={{top: '-15%', left: '-50%', transform: 'translate3d(0px, 3397.69px, 0px)'}} data-jarallax-element={-100} alt="" className="absolute__bg parallax__img" data-jarallax-original-styles=" top:-15%; left:-50%;" />
                                        <div style={{position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none', zIndex: -100}} id="jarallax-container-9">
                                            <div style={{position: 'fixed'}} />
                                        </div>
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-4 vc_col-md-4 vc_col-xs-12" style={{zIndex: 6}}>
                                                <div className="vc_column-inner vc_custom_1539654922435">
                                                    <div className="wpb_wrapper ">
                                                        <div className="press__item home4 aos-init aos-animate" data-aos="flip-left" style={{height: '361px'}}>
                                                            <div className="press__item-logo"><img src={'img/metamask.png'} alt="metamask" /></div>
                    <p>
                        {lng.messages["home.advdesc1"]}

                    </p></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-4 vc_col-md-4 vc_col-xs-12" style={{zIndex: 5}}>
                                                <div className="vc_column-inner vc_custom_1541324763007">
                <div className="wpb_wrapper ">
                    <div className="press__item home4 aos-init aos-animate" data-aos="flip-left" data-aos-delay={200} style={{height: '361px'}}>
                        <div className="press__item-logo"><img src={'img/nft.jpg'} alt="Logo_2.png" /></div>
                        <p>
                            {lng.messages["home.advdesc2"]}
                        </p></div>
                </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-4 vc_col-md-4 vc_col-xs-12" style={{zIndex: 4}}>
                                                <div className="vc_column-inner vc_custom_1541324770184">
    <div className="wpb_wrapper ">
        <div className="press__item home4 aos-init aos-animate" data-aos="flip-left" data-aos-delay={400} style={{height: '361px'}}>
            <div className="press__item-logo"><img src={'img/taraftar.png'} alt="Logo_3.png" /></div>
            <p>
                {lng.messages["home.advdesc3"]}
            </p></div>
    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_row-full-width vc_clearfix" />
                    </div>




                    <div className="p-30 bg-gray">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <img src={'img/cihazlar.png'} alt="metamask" />
                                </div>
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-md-12 app-img">
                                            <div className="ilkbaslik">{lng.messages["home.mobtitle"]}</div>
                                            <div className="ikincibaslik">{lng.messages["home.mobdesc"]}</div>
                                        </div>
                                        <div className="col-md-12 app-img"><img src={'img/play.png'} alt="metamask" /></div>
                                        <div className="col-md-12 app-img"><img src={'img/iphonestore.png'} alt="metamask" /></div>
                                        <div className="col-md-12 app-imgh"><img src={'img/huaapp.png'} alt="metamask" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="vc_row-full-width vc_clearfix" />
                    <div className="container">
                        <div  className="ult-responsive vc_row wpb_row vc_row-fluid vc_custom_1540240910639 vc_row-has-fill" style={{position: 'relative', left: '-189.6px', boxSizing: 'border-box', width: '1519px', paddingLeft: '189.6px', paddingRight: '189.4px'}}>
                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-3 vc_col-xs-12" style={{zIndex: 4}}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper ">
                                    <Link to="/" className="footer-logo logo">
                                        <img alt="" src={'img/slogo.png'} className="logo_img-alt" />
                                    </Link>
                                        <p className="white">
                                            {lng.messages["home.footer"]}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-3 vc_col-xs-12" style={{zIndex: 3}}>
        <div className="vc_column-inner">
            <div className="wpb_wrapper footer-menu">
                <div className="fbaslik">  {lng.messages["home.about"]}</div>
                <div  className="footer-menu">
                    <Link to="/home" title="link">{lng.messages["about"]}</Link></div>
                <div  className="footer-menu">
                    <Link to="/home" title="link">{lng.messages["kariyer"]}</Link></div>
                <div  className="footer-menu">
                    <Link to="/home" title="link">{lng.messages["iletisim"]}</Link></div>
                <div  className="footer-menu">
                    <Link to="/home" title="link">{lng.messages["topluluk"]}</Link>
                </div>
                <div  className="footer-menu">
                    <Link to="/home" title="link">{lng.messages["gizlilik"]}</Link>
                </div>
            </div>
        </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-3 vc_col-xs-12" style={{zIndex: 2}}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper footer-menu">
                                        <div className="fbaslik">{lng.messages["home.services"]}</div>
                                        <div  className="footer-menu">
                                            <Link to="/home" title="link">{lng.messages["buycrypto"]}</Link></div>
                                        <div  className="footer-menu">
                                            <Link to="/home" title="link">{lng.messages["downloads"]}</Link></div>
                                        <div  className="footer-menu">
                                            <Link to="/home" title="link">{lng.messages["kurumsal"]}</Link></div>
                                        <div  className="footer-menu">
                                            <Link to="/home" title="link">{lng.messages["reference"]}</Link>
                                        </div>
                                        <div  className="footer-menu">
                                            <Link to="/home" title="link">{lng.messages["sitemap"]}</Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-3 vc_col-xs-12" style={{zIndex: 1}}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper footer-menu">
                                        <div className="fbaslik">{lng.messages["home.products"]}</div>
                                        <div  className="footer-menu">
                                            <Link to="/home" title="link">{lng.messages["home.exchange"]}</Link></div>
                                        <div  className="footer-menu">
                                            <Link to="/home" title="link">{lng.messages["topnav.apps.nft"]} {lng.messages["buy"]}</Link></div>
                                        <div  className="footer-menu">
                                            <Link to="/home" title="link">{lng.messages["launchpad"]}</Link></div>
                                        <div  className="footer-menu">
                                            <Link to="/home" title="link">Trust Wallet</Link>
                                        </div>
                                        <div  className="footer-menu">
                                            <Link to="/home" title="link">Metamask</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_row-full-width vc_clearfix" />
                    </div>
                </div>
            </div>
        );
};
