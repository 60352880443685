import React, { useState } from "react";
import FileBase64 from "react-file-base64";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {  Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateUserwallets } from "../../actions/userwallets";

const useStyles = makeStyles((theme) => ({
    textField: {
        marginBottom: theme.spacing(2),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
}));

const tags = ["fun", "programming", "health", "science"];

const userwalletsSchema = yup.object().shape({

    tag: yup.mixed().oneOf(tags),
});

const EditUserwalletsForm = ({ userwallets, closeEditMode }) => {
    const dispatch = useDispatch();

    const [file, setFile] = useState(userwallets?.image);
    const { register, handleSubmit, reset } = useForm({
        resolver: yupResolver(userwalletsSchema),
    });

    const onSubmit = (data) => {
        const updatedUserwallets = {
            _id: userwallets._id,
            ...data,
            image: file,
        };
        dispatch(updateUserwallets(userwallets._id, updatedUserwallets));

        reset();
        setFile(null);
        closeEditMode();
    };

    const classes = useStyles();
    return (
        <div>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <input  className={classes.textField} defaultValue={userwallets?.title} size="small"  id="content" {...register("title")}  />
                <input  className={classes.textField} defaultValue={userwallets?.symbol} size="small"  id="content" {...register("symbol")}  />
                <select {...register("status")}>
                    <option defaultValue={userwallets?.status}>{userwallets?.status}</option>
                    <option defaultValue="2">Pasif</option>
                    <option defaultValue="3">Beklemede</option>


                </select>

                <input className={classes.textField} defaultValue={userwallets?.price} size="small"  id="content" {...register("price")}  />


                <FileBase64 multiple={false} onDone={({ base64 }) => setFile(base64)} />
                <div className={classes.buttons}>
                    <Button color="primary" variant="outlined" onClick={closeEditMode}>
                        Vazgeç
                    </Button>{" "}

                    <Button color="secondary" variant="outlined" type="submit">
                        Kaydet
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default EditUserwalletsForm;
