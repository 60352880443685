import React from "react";
import {botemirEkle} from "../actions/general";
import {useDispatch, useSelector} from "react-redux";
const Order = (props) => {
    //console.log(props.order.buyprice);
   const arti = parseInt(props.digits)+3;
    const dispatch = useDispatch();
    const handleSetBot = (bot) => {
        delete bot.botid;
        delete bot._id;
        delete bot.user;
        dispatch(botemirEkle("alisemirekle",bot,"alisemri"))
    }
    return (
            <tr key={props.order._id} onClick={() => handleSetBot(props.order)} className={"ordertr "+props.arkaplan}>
                <td className={props.renk}>{parseFloat(props.order.buyprice).toFixed(arti)}</td>
                <td className="exmid">{props.order.amount}</td>
                <td className="exend">{props.order.buytotal}</td>
            </tr>
    )
}
export  default Order;
