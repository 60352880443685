import * as api from "../api/general";
import * as types from "./types";
export const fetchRows = (datatype,query,tablename) => async (dispatch) => {
    //datatype çekilen tablo, query = sorgu,  tablename= sitede kullandığım redux tablom
    try {
        const { data } = await api.fetchRows(datatype, query, tablename);

        dispatch({
            type: types.LIST_ALL,
            extradata:tablename,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const fetchOrders = (datatype,query,tablename) => async (dispatch) => {
    //datatype çekilen tablo, query = sorgu,  tablename= sitede kullandığım redux tablom
    try {
        const { data } = await api.fetchOrders(datatype, query, tablename);

        dispatch({
            type: types.LIST_ORDERS,
            extradata:tablename,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const deleteorderData = (datatype,query,tablename) => async (dispatch) => {
    try {
        const { data } = await api.deleteorderData(datatype, query, tablename);
        dispatch({
            type: types.DELETE_DATA,
            extradata:tablename,
            payload: data,
        });

    } catch (error) {
        console.log(error);
    }
};
export const fetchData = (datatype) => async (dispatch) => {
    try {
        const { data } = await api.fetchData(datatype);
        //console.log(data);
        dispatch({
            type: types.LIST_DATA,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const fetchAll = (datatype) => async (dispatch) => {
    try {
        const { data } = await api.fetchData(datatype);

        dispatch({
            datatype:datatype,
            type: types.LIST_USERS,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const updateMultiple = (secenek,post) => async (dispatch) => {
    try {
        const { data } = await api.updateMultiple(secenek,post);
        dispatch({
            type: types.UPDATE,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const deleteData = (secenek,id) => async (dispatch) => {
    try {
        const { data } = await api.deleteData(secenek,id);
        dispatch({
            type: types.DELETE,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const addnewData = (options,post) => async (dispatch) => {

    try {
        const { data } = await api.addnewData(options,post);
        dispatch({
            type: types.CREATE,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const updateDatas = (table,upquery,resultcol) => async (dispatch) => {

    try {
        const { data } = await api.updateDatas(table,upquery,resultcol);
        dispatch({
            type: types.UPDATE_DATAS,
            extradata:resultcol,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const deleteGeneral = (table,upquery,resultcol) => async (dispatch) => {
    try {
        const { data } = await api.deleteGeneral(table,upquery);
        dispatch({
            type: types.DELETE_GENERAL,
            extradata:resultcol,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};

export const botemirEkle = (tablo,emir,yon) => async (dispatch) => {
    try {

        dispatch({
            type: types.UPDATE_DATAS,
            extradata:yon,
            payload: emir,
        });
    } catch (error) {
        console.log(error);
    }
};
export const botSorgu = (table,upquery,resultcol) => async (dispatch) => {
    try {
        const { data } = await api.botSorgu(table,upquery);
        dispatch({
            type: types.BOT_SORGU,
            extradata:resultcol,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const updateData = (options,post) => async (dispatch) => {

    try {
        const { data } = await api.updateData(options,post);
        dispatch({
            type: types.UPDATE,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
