import jwtDecode from "jwt-decode";

export default function Auth() {

    if (localStorage.getItem("gsdhhrxchbhlkpkakd")) {
        try {
            const jwt_Token_decoded = jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));
            if (jwt_Token_decoded.exp * 1000 < Date.now()) {
                localStorage.clear();
                return false;
            } else {
               // console.log("Token Devam Ediyor");
                return true;
            }
        } catch (err) {
            localStorage.clear();
           // console.log(err);
            return false;
        }

    } else {
        localStorage.clear();
        //console.log("token yok");
        return false;
    }

}
