import React, {Component, useState, useEffect } from 'react';
import { Navbar, Nav, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ThemeConsumer } from '../context/ThemeContext';
import Auth from "../actions/Auth";
import jwtDecode from "jwt-decode";
import Cook from "../actions/Cook";
import NavLanguage from "./NavLanguage";
import MenuContent from "./MenuContent";
import {connect} from "react-redux";
const Header = (lng) => {
  console.log(lng);
  const [locale, setLocale] = useState("en");
  const [auth, setAuth] = useState(null);
  const [userinfo, setUserinfo] = useState({});
  useEffect(() => {

 userEkle();
     }, []);


  const   userEkle = () => {
      if(localStorage.getItem("gsdhhrxchbhlkpkakd"))
      {
        console.log("userdata");

        setUserinfo(localStorage.getItem("gsdhhrxchbhlkpkakd"));
        setAuth(Auth());

      }
      let el = document.querySelector('#darkTheme');
      if (el) {
        el.addEventListener('click', function () {
          document.body.classList.toggle('dark');
        });
      }

    }

  /*
constructor(props) {
  super(props);
  this.state = {
     locale: "",
    userinfo: [],
    auth:null

  }
}
   componentDidMount() {
     this.userEkle();
    let el = document.querySelector('#darkTheme');
    if (el) {
      el.addEventListener('click', function () {
        document.body.classList.toggle('dark');
      });
    }
  }
   userEkle = () => {
    if(localStorage.getItem("gsdhhrxchbhlkpkakd"))
    {
     this.props.adduserdata(jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd")));
      this.setState({
        userinfo: jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd")),
        auth:Auth()
      })
    }

  }
*/
    return (
      <>
<Cook/>
        <header className="light-bb">
          <Navbar expand="lg">
            <Link className="navbar-brand" to="/">
              <ThemeConsumer>
                {({ data }) => {
                  return data.theme === 'dark' ? (
                    <img src={process.env.PUBLIC_URL +'/img/slogo.png'} alt="logo" />
                  ) : (
                    <img src={process.env.PUBLIC_URL +'/img/slogo.png'} alt="logo" />
                  );
                }}
              </ThemeConsumer>
            </Link>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbar-nav mr-auto">
                <MenuContent/>
              </Nav>
              <Nav className="navbar-nav ml-auto">
                <NavLanguage />
                <Dropdown className="header-custom-icon">
                  <ThemeConsumer>
                    {({ data, update }) => (
                      <Button variant="default" onClick={update} id="darkTheme">
                        {data.theme === 'light' ? (
                          <i className="icon ion-md-moon" />
                        ) : (
                          <i className="icon ion-md-sunny" />
                        )}
                      </Button>
                    )}
                  </ThemeConsumer>
                  {auth ?
                  <Dropdown.Toggle variant="default">
                    <i className="icon ion-md-notifications" />
                    <span className="circle-pulse" />
                  </Dropdown.Toggle>
                      :<>
                        <Link to="/login" className={"btn btn-login btn--big"}> {lng.messages["login"]} </Link>
                        <Link to="/signup" className={"btn btn-register btn--big"}>{lng.messages["signup"]}</Link>
                      </>
                  }
                  {auth ?
                  <Dropdown.Menu>
                    <div className="dropdown-header d-flex align-items-center justify-content-between">
                      <p className="mb-0 font-weight-medium">
                        6 Yeni Bildirim
                      </p>
                      <a href="/#" className="text-muted">
                        Hepsini Temizle
                      </a>
                    </div>
                    <div className="dropdown-body">
                      <a href="/#" className="dropdown-item">
                        <div className="icon">
                          <i className="icon ion-md-lock"> </i>
                        </div>
                        <div className="content">
                          <p>Şifre Değiştir</p>
                          <p className="sub-text text-muted">5 san önce</p>
                        </div>
                      </a>
                      <a href="/#" className="dropdown-item">
                        <div className="icon">
                          <i className="icon ion-md-alert"> </i>
                        </div>
                        <div className="content">
                          <p>Solve the security issue</p>
                          <p className="sub-text text-muted">10 dk önce</p>
                        </div>
                      </a>
                    </div>
                    <div className="dropdown-footer d-flex align-items-center justify-content-center">
                      <a href="/#">Tümünü Gör</a>
                    </div>
                  </Dropdown.Menu>
                      :<></>
                  }
                </Dropdown>
                {auth ?
                <Dropdown className="header-img-icon">
                  <Dropdown.Toggle variant="default">
                    <img src={process.env.PUBLIC_URL+'/img/avatar.svg'} alt="avatar" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropdown-header d-flex flex-column align-items-center">
                      <div className="figure mb-3">
                        <img src={'img/avatar.svg'} alt="" />
                      </div>
                      <div className="info text-center">
                        <p className="name font-weight-bold mb-0">{userinfo.name}</p>
                        <p className="email text-muted mb-3">
                          {userinfo.phone}
                        </p>
                      </div>
                    </div>
                    <div className="dropdown-body">
                      <ul className="profile-nav">
                        <li className="nav-item">
                          <Link to="/profile" className="nav-link">
                            <i className="icon ion-md-person"> </i>
                            <span>Profil</span>
                          </Link>
                        </li>

                          <li className="nav-item">
                              <Link to="/bankaodeme" className="nav-link">
                                  <i className="icon ion-md-wallet"> </i>
                                  <span>Para Yatırma</span>
                              </Link>
                          </li>
                        <li className="nav-item">
                          <Link to="/wallet" className="nav-link">
                            <i className="icon ion-md-wallet"> </i>
                            <span>Cüzdanım</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/settings" className="nav-link">
                            <i className="icon ion-md-settings"> </i>
                            <span>Ayarlar</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/logout" className="nav-link red">
                            <i className="icon ion-md-power"> </i>
                            <span>Çıkış</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Dropdown.Menu>

                </Dropdown>
                    :<></>
                }
              </Nav>
            </Navbar.Collapse>
          </Navbar>

        </header>
      </>
    );
  }
  const mapStateToProps = ({ theme }) => {
      const {locale } = theme;
      return {locale};
  };

  export default connect(mapStateToProps, {})(Header)
