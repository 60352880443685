import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

export default function MarketsList(props) {
  return (
    <>
      <div className="markets pb70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="markets-pair-list">
                <Tabs defaultActiveKey="btc">
                  <Tab eventKey="favorites" title={props.messages["markets.favorites"]}>
                    <div className="table-responsive">
                      <table className="table star-active">
                        <thead>
                          <tr>
                            <th>{props.messages["exchange.pairs"]}</th>
                            <th>{props.messages["coin"]}</th>
                            <th>{props.messages["markets.last_price"]}</th>
                            <th>{props.messages["markets.change"]} (24H)</th>
                            <th>{props.messages["markets.high"]} (24H)</th>
                            <th>{props.messages["markets.low"]} (24h)</th>
                            <th>{props.messages["markets.volume"]} (24h)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr data-href="exchange-light.html">
                            <td>
                              <i className="icon ion-md-star"></i> ETH/BTC
                            </td>
                            <td>
                              <img src={'img/icon/1.png'} alt="eth" /> ETH
                            </td>
                            <td>7394.06</td>
                            <td className="green">+0.78%</td>
                            <td>7444.91</td>
                            <td>7267.06</td>
                            <td>431,687,258.77</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Tab>
                  <Tab eventKey="btc" title="BTC">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{props.messages["exchange.pairs"]}</th>
                            <th>{props.messages["coin"]}</th>
                            <th>{props.messages["markets.last_price"]}</th>
                            <th>{props.messages["markets.change"]} (24H)</th>
                            <th>{props.messages["markets.high"]} (24H)</th>
                            <th>{props.messages["markets.low"]} (24h)</th>
                            <th>{props.messages["markets.volume"]} (24h)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr data-href="exchange-light.html">
                            <td>
                              <i className="icon ion-md-star"></i> ETH/BTC
                            </td>
                            <td>
                              <img src={'img/icon/1.png'} alt="eth" /> ETH
                            </td>
                            <td>7394.06</td>
                            <td className="green">+0.78%</td>
                            <td>7444.91</td>
                            <td>7267.06</td>
                            <td>431,687,258.77</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Tab>
                  <Tab eventKey="kcs" title="KCS">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{props.messages["exchange.pairs"]}</th>
                            <th>{props.messages["coin"]}</th>
                            <th>{props.messages["markets.last_price"]}</th>
                            <th>{props.messages["markets.change"]} (24H)</th>
                            <th>{props.messages["markets.high"]} (24H)</th>
                            <th>{props.messages["markets.low"]} (24h)</th>
                            <th>{props.messages["markets.volume"]} (24h)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr data-href="exchange-light.html">
                            <td>
                              <i className="icon ion-md-star"></i> ETH/KCS
                            </td>
                            <td>
                              <img src={'img/icon/1.png'} alt="eth" /> ETH
                            </td>
                            <td>7394.06</td>
                            <td className="green">+0.78%</td>
                            <td>7444.91</td>
                            <td>7267.06</td>
                            <td>431,687,258.77</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Tab>
                  <Tab eventKey="usdt" title="USDT">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{props.messages["exchange.pairs"]}</th>
                            <th>{props.messages["coin"]}</th>
                            <th>{props.messages["markets.last_price"]}</th>
                            <th>{props.messages["markets.change"]} (24H)</th>
                            <th>{props.messages["markets.high"]} (24H)</th>
                            <th>{props.messages["markets.low"]} (24h)</th>
                            <th>{props.messages["markets.volume"]} (24h)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr data-href="exchange-light.html">
                            <td>
                              <i className="icon ion-md-star"></i> ETH/USDT
                            </td>
                            <td>
                              <img src={'img/icon/1.png'} alt="eth" /> ETH
                            </td>
                            <td>7394.06</td>
                            <td className="green">+0.78%</td>
                            <td>7444.91</td>
                            <td>7267.06</td>
                            <td>431,687,258.77</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Tab>
                  <Tab eventKey="alts" title="ALTS">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{props.messages["exchange.pairs"]}</th>
                            <th>{props.messages["coin"]}</th>
                            <th>{props.messages["markets.last_price"]}</th>
                            <th>{props.messages["markets.change"]} (24H)</th>
                            <th>{props.messages["markets.high"]} (24H)</th>
                            <th>{props.messages["markets.low"]} (24h)</th>
                            <th>{props.messages["markets.volume"]} (24h)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr data-href="exchange-light.html">
                            <td>
                              <i className="icon ion-md-star"></i> ETH/ALTS
                            </td>
                            <td>
                              <img src={'img/icon/1.png'} alt="eth" /> ETH
                            </td>
                            <td>7394.06</td>
                            <td className="green">+0.78%</td>
                            <td>7444.91</td>
                            <td>7267.06</td>
                            <td>431,687,258.77</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </Tab>
                </Tabs>
                <div className="text-center">
                  <a href="#!" className="load-more btn">
                    Load More <i className="icon ion-md-arrow-down"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
