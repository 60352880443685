import React from "react";
import {useDispatch} from "react-redux";
import {botemirEkle} from "../actions/general";
const Sellorder = (props) => {
    const arti = parseInt(props.digits)+3;
    const dispatch = useDispatch();
    const handleSetBot = (bot) => {
        delete bot.botid;
        delete bot._id;
        delete bot.user;
        dispatch(botemirEkle("satisemirekle",bot,"satisemri"))
    }
    return (
        <tr key={props.order._id} onClick={() => handleSetBot(props.order)} className={"ordertr "+props.arkaplan}>
            <td className={props.renk}>{parseFloat(props.order.sellprice).toFixed(arti)}</td>
            <td className="exmid">{props.order.amount}</td>
            <td className="exend">{props.order.selltotal}</td>
        </tr>
    );
}
export  default Sellorder;
