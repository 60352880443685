import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
const olustur ="olustur";
const guncelle ="guncelle/";
const sil ="sil/";

const nodeserver=WEB_CONFIG.server+"/api/news/";

axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

export const fetchNews = async () => await axios.get(nodeserver);

export const fetchSingleNews = async (id) =>
    await axios.get(`${nodeserver}${id}`);

export const createNews = async (news) => await axios.post(`${nodeserver}${olustur}`, news);

export const updateNews = async (id, updatedNews) =>
    await axios.post(`${nodeserver}${guncelle}${id}`, updatedNews);
export const deleteNews = async (id) =>
    await axios.get(`${nodeserver}${sil}${id}`);
