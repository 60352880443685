import React, { Component } from 'react';
import jwtDecode from "jwt-decode";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

class  Logout extends Component {

    componentDidMount() {
        const jdc = jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));
        document.cookie = jdc.cjwtpto + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.clear();
        this.props.adduserdata([]);
    }
  render () {
    return (
        <Redirect to='/'  />
    );
}

}


const mapDispatchToProps = (dispatch) => {

    return {
        adduserdata: (userinfo) => dispatch({type: 'USER_DATA', payload: userinfo })
    }
}

const mapStateToProps = (props) => {

    return props;
};
export default connect(mapStateToProps, mapDispatchToProps)(Logout)
