import React from 'react';
import MarketCarousel from '../components/MarketCarousel';
import MarketsList from '../components/MarketsList';
import Header from "../components/Header";
import {connect} from "react-redux";

const markets = (lng) => {

  return (
    <>
          <Header {...lng}  />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <MarketCarousel {...lng} />
          </div>
        </div>
      </div>
      <MarketsList {...lng} />
    </>
  );
}
const mapStateToProps = ({ theme }) => {
    const {locale } = theme;
    return {locale};
};

export default connect(mapStateToProps, {  })(markets);
