const ExchangeTop = (props) => {
    return(
        <div className="pairs_top row sm-gutters exchange-top">

            <div className="col-sm-10 flxbasis col-md-12">
                <div className="row sm-gutters">
                    <div className="col-sm-4 col-md-2">
                        <div className=" ex_pairs">{props[0]+"/"+props[1]}</div>
                        <div className="ex_pairs_sub">{props[0]+"/"+props[1]} {props.messages["market"]}</div>
                    </div>
                    <div className="col-sm-4 col-md-2">
                        <div className="green ex_top_price"> 47,087.35</div>
                        <div className="ex_top_price_sub"> ₺626,502.76</div>
                    </div>
                    <div className="col-sm-4 col-md-2">
                        <div className="ex_top_cols"> 24 {props.messages["hours_change"]}</div>
                        <div className="red ex_top_cols_sub">-215.66 -0.46%</div>
                    </div>
                    <div className="col-sm-4 col-md-2">
                        <div className="ex_top_cols">24{props.messages["hours_high"]}</div>
                        <div className="green ex_top_cols_sub">47,954.63</div>
                    </div>
                    <div className="col-sm-4 col-md-2">
                        <div className="ex_top_cols">24{props.messages["hours_low"]}</div>
                        <div className="red ex_top_cols_sub">46,715.39</div>
                    </div>
                    <div className="col-sm-4 col-md-2">
                        <div className="ex_top_cols">24S {props.messages["volume"]}({props[0]})</div>
                        <div className="ex_top_cols_sub">18,150.75</div>
                    </div>

                </div>
            </div>
        </div>

    );

}
export default ExchangeTop;
