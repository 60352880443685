import * as api from "../api/orders";
import * as types from "./types";

export const fetchOrders = () => async (dispatch) => {

    try {
        const { data } = await api.fetchOrders();

        dispatch({
            type: types.FETCH_ORDERS,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const fetchOrdersByField = (market,side) => async (dispatch) => {
    try {
        const { data } = await api.fetchOrdersByField(market,side);
        if(side==='BUY') {
            dispatch({
                type: types.FETCH_ORDERS_BYFIELD_BUY,
                payload: data,
            });
        }
        else {
            dispatch({
                type: types.FETCH_ORDERS_BYFIELD_SELL,
                payload: data,
            });
        }

    } catch (error) {
        console.log(error);
    }
};

export const fetchSingleOrder = (id) => async (dispatch) => {
    try {

        const { data } = await api.fetchSingleOrder(id);
        dispatch({
            type: types.FETCH_SINGLE_ORDER,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};

export const createOrders = (post) => async (dispatch) => {
    try {
        const { data } = await api.createOrders(post);
        dispatch({
            type: types.CREATE_ORDER,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};
export const createBotOrders = (post) => async (dispatch) => {
    try {
        const { data } = await api.createBotOrders(post);
        dispatch({
            type: types.CREATE_BOTORDER,
            payload: data,
        });
    } catch (error) {
        console.log(error);
    }
};

export const updateOrder = (id, post) => async (dispatch) => {
    try {
        const { data } = await api.updateOrder(id, post);

        dispatch({ type: types.UPDATE_ORDER, payload: data });
    } catch (error) {
        console.log(error.message);
    }
};

export const deleteOrder = (id) => async (dispatch) => {
    try {
        const { data } = await api.deleteOrder(id);
        dispatch({
            type: types.DELETE_ORDER,
            payload: data._id,
        });
    } catch (error) {
        console.log(error);
    }
};
