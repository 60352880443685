import React from 'react';

const Adminindex = () => {

        return (
            <div>
                Admin index sayfası
            </div>
        );

};


export default Adminindex;