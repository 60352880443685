import React, { useState } from "react";
import FileBase64 from "react-file-base64";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createNews } from "../../actions/news";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
}));



const newsSchema = yup.object().shape({

});

const AddNewsForm = ({ open, handleClose }) => {
    const dispatch = useDispatch();

    const [file, setFile] = useState(null);
    const { register, handleSubmit, reset } = useForm({
        resolver: yupResolver(newsSchema),
    });

    const onSubmit = (data) => {
        //console.log(data);
        dispatch(createNews({ ...data, image: file }));
        clearForm();
    };
    const clearForm = () => {
        reset();
        setFile(null);
        handleClose();
    };
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle> Yeni Yazı Oluştur</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To subscribe to this website, please enter your email address here. We
                    will send updates occasionally.
                </DialogContentText>
                <div >
                    <form  autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <input className={classes.textField} size="small"  {...register("title")} placeholder="Başlık" />
                        <input  className={classes.textField} size="small"   {...register("subtitle")} placeholder="Alt Başlık" />
                        <select {...register("status")}>
                            <option value="1">Aktif</option>
                            <option value="2">Pasif</option>
                            <option value="3">Beklemede</option>

                        </select>

                        <input  className={classes.textField}  {...register("description")} placeholder="Açıklama"  />

                        <FileBase64
                            multiple={false}
                            onDone={({ base64 }) => setFile(base64)}
                        />
                        <input
                            placeholder="Yayınla"
                            type="submit"
                            onClick={() => handleSubmit(onSubmit)}
                            color="primary"
                        />
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={clearForm} color="inherit">
                    Vazgeç
                </Button>



            </DialogActions>
        </Dialog>
    );
};

export default AddNewsForm;
