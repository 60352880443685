import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Divider, Button, Chip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EditNewsForm from "./Edit";
import { fetchSingleNews, deleteNews } from "../../actions/news";
import noImage from "../../assets/img/noimage.svg";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(8),
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
    content: {
        marginTop: theme.spacing(3),
    },
    image: {
        width: "100%",
        borderRadius: 5,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    chip: {
        marginTop: theme.spacing(1),
    },
}));

const NewsDetails = ({ history, match }) => {
    const { id } = match.params;
    const dispatch = useDispatch();

    const currentNews = useSelector((state) => state.news.currentNews);

    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        dispatch(fetchSingleNews(id));
    }, [dispatch, id]);

    const convertRelativeTime = (date) => {
        return moment(date).fromNow();
    };

    const removeNews = () => {
        dispatch(deleteNews(currentNews._id));
        history.push("/news");
    };

    const openEditMode = () => {
        setEditMode(true);
    };

    const closeEditMode = () => {
        setEditMode(false);
    };

    const classes = useStyles();
    return (
        <Paper className={classes.paper} elevation={0}>
            {editMode ? (
                <EditNewsForm news={currentNews} closeEditMode={closeEditMode} />
            ) : (
                <div>
                    <div className={classes.header}>
                        <Typography variant="h5" gutterBottom>
                            {currentNews?.title}
                        </Typography>
                        <div>
                            <Button
                                color="primary"
                                variant="outlined"
                                startIcon={<EditIcon />}
                                onClick={openEditMode}
                            >
                                Düzenle
                            </Button>{" "}
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={removeNews}
                                startIcon={<DeleteIcon />}
                            >
                                Sil
                            </Button>
                        </div>
                    </div>

                    <Divider />
                    <Typography variant="overline" gutterBottom>
                        {currentNews?.subtitle}
                    </Typography>
                    <Typography variant="caption" component="p" gutterBottom>
                        {convertRelativeTime(currentNews?.createdAt)} by Didem
                    </Typography>
                    <Chip
                        label={`# ${currentNews?.tag}`}
                        variant="outlined"
                        className={classes.chip}
                    />

                    <div className={classes.content}>
                        <img
                            src={currentNews?.image || noImage}
                            alt="News"
                            className={classes.image}
                        />
                        <Typography variant="body1" gutterBottom>
                            {currentNews?.content}
                        </Typography>
                    </div>
                </div>
            )}
        </Paper>
    );
};

export default NewsDetails;
