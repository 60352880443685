import React from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import {Snackbar, Table, Tooltip} from "@material-ui/core";
import {Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {deleteorderData, fetchRows, updateDatas, deleteGeneral, botSorgu, fetchOrders} from "../actions/general";
import jwtDecode from "jwt-decode";
import {useParams} from "react-router-dom";
export default function HistoryOrder(props) {
  function getuserlocal() {
    if(localStorage.getItem("gsdhhrxchbhlkpkakd"))
    {
      return jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd")).id
    }
    else {
      return null;
    }
  }
  const userid= getuserlocal();
  const { market } = useParams();
  const dispatch = useDispatch();
  const allData = useSelector((state) => state.general);
  const [opener, setOpener] = React.useState(false);
  const ordersorgu= { limit: '44', "query": {"status": "NEW", "symbol": market} }
  const myordersorgu= { limit: '0', "query": {type:"WEB","status": "NEW", "symbol": market, "user": userid} }
  const buyselsorgu= { limit: '30', "query": {botside:"BUYSELL",type:"BOT", "symbol": market, "user": userid} }
  const sellbuysorgu= { limit: '0', "query": {botside:"SELLBUY",type:"BOT", "symbol": market, "user": userid, $or: [{'status': 'NEW'}, {'status': 'CANCELED'}]   } }


  const bsorders = allData.extradata.buysellorders?allData.extradata.buysellorders:[];
  const sborders = allData.extradata.sellbuyorders?allData.extradata.sellbuyorders:[];
  const botsorgu = allData.extradata?allData.extradata.botsorgu:[];

  const sellbotsorgu = allData.extradata.sellbotsorgu?allData.extradata.sellbotsorgu:[];
  const [deletestate, setDeleteState] = React.useState({
    open:false,
    alertclass: 'success',
    alertmessage: ''
  });
  const [updatestate, setUpdatestate] = React.useState({
    uopen:false,
    ualertclass: 'success',
    ualertmessage: ''
  });

  const [botalsat, setBotalsat] = React.useState({
    status:'danger',
    action:'Başlat',
    actionstatus:'success',
    actionicon:'play',
    title:"Durduruldu",

  });

  const [botsatal, setBotsatal] = React.useState({
    status:'danger',
    action:'Başlat',
    actionstatus:'success',
    actionicon:'play',
    title:"Durduruldu",

  });

  const {alertmessage,alertclass } = deletestate;
  const { uopen ,ualertclass,ualertmessage } = updatestate;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteState({open:false});
    setUpdatestate({uopen:false});
  };
  const handleDelete = (order) => {
    dispatch(deleteorderData("orders",order,"orderdelete")).then((e => {
      const deleteresult = allData.extradata.orderdelete?allData.extradata.orderdelete:null;

      if(deleteresult) {

        setDeleteState(deletestate => ({...deletestate, alertclass: deleteresult.status?deleteresult.status:null , alertmessage:deleteresult.message?deleteresult.message:null, open: true}));

      }
      dispatch(fetchRows("orders",ordersorgu,"allorders"));
      dispatch(fetchRows("orders",myordersorgu,"myorders"));
      dispatch(fetchRows("orders",buyselsorgu,"buysellorders"));
      dispatch(fetchRows("orders",sellbuysorgu,"sellbuyorders"));
    }));
  }
  const handleBotsorgu = (bot) => {
    if(bot.status==='danger') {
      //Bu durumda bot çalıştırılacak
      const botsorgusu = ({...bot,userid:userid,market:market,side:"BUYSELL",action: "Durdur",status:"success",actionicon: "pause",actionstatus: "danger", title: "Bot Çalışıyor"});
      dispatch(botSorgu("userbots",botsorgusu,"botsorgu")).then((e => {
        setBotalsat(botalsat => ({...botalsat, action: botsorgusu.action,actionstatus:botsorgusu.actionstatus, status:botsorgusu.status, title:botsorgusu.title,actionicon:botsorgusu.actionicon}));
        dispatch(fetchRows("orders",ordersorgu,"allorders"));
        dispatch(fetchRows("orders",myordersorgu,"myorders"));
        dispatch(fetchRows("orders",buyselsorgu,"buysellorders"));
        dispatch(fetchRows("orders",sellbuysorgu,"sellbuyorders"));

      }));
    }
    else {
      //Bu durumda bot durdurulacak
      const botsorgusu = ({...bot,userid:userid,market:market,side:"BUYSELL",action: "Başlat",status:"danger",actionicon: "play",actionstatus: "success", title: "Bot Durduruldu"});
      dispatch(botSorgu("userbots",botsorgusu,"botsorgu")).then((e => {
        setBotalsat(botalsat => ({...botalsat, action: botsorgusu.action,actionstatus:botsorgusu.actionstatus, status:botsorgusu.status, title:botsorgusu.title,actionicon:botsorgusu.actionicon}));
        dispatch(fetchRows("orders",ordersorgu,"allorders"));
        dispatch(fetchRows("orders",myordersorgu,"myorders"));
        dispatch(fetchRows("orders",buyselsorgu,"buysellorders"));
        dispatch(fetchRows("orders",sellbuysorgu,"sellbuyorders"));

      }));

    }

  }
  const handleSellBotsorgu = (bot) => {
    if(bot.status==='danger') {
      //Bu durumda bot çalıştırılacak
      const botsorgusu = ({...bot,userid:userid,market:market,side:"SELLBUY",action: "Durdur",status:"success",actionicon: "pause",actionstatus: "danger", title: "Bot Çalışıyor"});
      dispatch(botSorgu("userbots",botsorgusu,"sellbotsorgu")).then((e => {
        dispatch(fetchRows("orders",ordersorgu,"allorders"));
        dispatch(fetchRows("orders",myordersorgu,"myorders"));
        dispatch(fetchRows("orders",buyselsorgu,"buysellorders"));
        dispatch(fetchRows("orders",sellbuysorgu,"sellbuyorders"));
        const mysorgu= { limit: '1', "query": { userid:userid, market:market, side: 'SELLBUY'}}
        dispatch(fetchRows("userbots",mysorgu,"sellbotsorgu")).then((e => {
          setBotsatal(botsatal => ({...botsatal, action: botsorgusu.action,actionstatus:botsorgusu.actionstatus, status:botsorgusu.status, title:botsorgusu.title,actionicon:botsorgusu.actionicon}));
        }));
      }));
    }
    else {
      //Bu durumda bot durdurulacak
      const botsorgusu = ({...bot,userid:userid,market:market,side:"SELLBUY",action: "Başlat",status:"danger",actionicon: "play",actionstatus: "success", title: "Bot Durduruldu"});
      dispatch(botSorgu("userbots",botsorgusu,"sellbotsorgu")).then((e => {
        dispatch(fetchRows("orders",ordersorgu,"allorders"));
        dispatch(fetchRows("orders",myordersorgu,"myorders"));
        dispatch(fetchRows("orders",buyselsorgu,"buysellorders"));
        dispatch(fetchRows("orders",sellbuysorgu,"sellbuyorders"));

        const mysorgu= { limit: '1', "query": { userid:userid, market:market, side: 'SELLBUY'}}
        dispatch(fetchRows("userbots",mysorgu,"sellbotsorgu")).then((e => {
          setBotsatal(botsatal => ({...botsatal, action: botsorgusu.action,actionstatus:botsorgusu.actionstatus, status:botsorgusu.status, title:botsorgusu.title,actionicon:botsorgusu.actionicon}));

        }));

      }));

    }

  }
  const handleDeletes = (order) => {

    if(order.botside==='BUYSELL')  {

      dispatch(deleteorderData("orders",order,"deletestatus")).then((e => {
        const deleteresult = allData.extradata.deletestatus?allData.extradata.deletestatus:null;
        if(deleteresult) {

          const ordersorgu= { limit: '44', "query": {"status": "NEW", "symbol": market} }
          dispatch(fetchRows("orders",ordersorgu,"allorders"));
          setOpener(true);
          setDeleteState(deletestate => ({...deletestate, alertclass: deleteresult.status?deleteresult.status:null , alertmessage:deleteresult.message?deleteresult.message:null}));


          dispatch(fetchRows("orders",myordersorgu,"myorders"));
          dispatch(fetchRows("orders",sellbuysorgu,"sellbuyorders"));

        }
      }));
    }

    else {
      dispatch(deleteorderData("orders",order,"deletestatus")).then((e => {
        const deleteresult = allData.extradata.deletestatus?allData.extradata.deletestatus:null;
        if(deleteresult) {


          const ordersorgu= { limit: '44', "query": {"status": "NEW", "symbol": market} }
          dispatch(fetchRows("orders",ordersorgu,"allorders"));
          setOpener(true);
          setDeleteState(deletestate => ({...deletestate, alertclass: deleteresult.status?deleteresult.status:null , alertmessage:deleteresult.message?deleteresult.message:null}));
          dispatch(fetchRows("orders",myordersorgu,"myorders"));
          dispatch(fetchRows("orders",sellbuysorgu,"sellbuyorders"));


        }
      }));
    }

  }

  const handleUpdate = (updates,table,query,resultcol) => {
    const upquery = { update:updates,quer:query }
    dispatch(updateDatas(table,upquery, resultcol)).then((e => {
      const buyselsorgu= { limit: '0', "query": {botside:"BUYSELL",type:"BOT", "symbol": market, "user": userid} }
      dispatch(fetchRows("orders",buyselsorgu,"buysellorders"));



      const ordersorgu= { limit: '44', "query": {"status": "NEW", "symbol": market} }
      dispatch(fetchRows("orders",ordersorgu,"allorders"));

    }));
  }
  const handleUpdatesell = (updates,table,query,resultcol) => {
    const upquery = { update:updates,quer:query }
    dispatch(updateDatas(table,upquery, resultcol)).then((e => {

      dispatch(fetchRows("orders",sellbuysorgu,"sellbuyorders"));


      const ordersorgu= { limit: '44', "query": {"status": "NEW", "symbol": market} }
      dispatch(fetchRows("orders",ordersorgu,"allorders"));
    }));
  }
  const getBots = () => {
    if(userid) {
      getSellBots();
      const mysorgu= { limit: '1', "query": { userid:userid, market:market, side: 'BUYSELL'}}
      dispatch(fetchRows("userbots",mysorgu,"botsorgu")).then((e => {
        if(botsorgu.length>0) {
          const botsor = botsorgu[0];
          setBotalsat(botalsat => ({...botalsat, action: botsor.action,actionstatus:botsor.actionstatus, status:botsor.status, title:botsor.title,actionicon:botsor.actionicon}));

        }
      }));

    }
    else {
      setBotalsat([]);
    }
  }


  const getSellBots = () => {
    if(userid) {
      const mysorgu= { limit: '1', "query": { userid:userid, market:market, status:'NEW', side: 'SELLBUY'}}
      dispatch(fetchRows("userbots",mysorgu,"sellbotsorgu")).then((e => {
        if(sellbotsorgu) {
          if(sellbotsorgu.length>0) {
            const botsor = sellbotsorgu[0];
            setBotsatal(botsatal => ({...botsatal, action: botsor.action,actionstatus:botsor.actionstatus, status:botsor.status, title:botsor.title,actionicon:botsor.actionicon}));
          }
        }
      }));

    }
    else {

      setBotsatal([]);
    }
  }
  return (
      <>
        <Snackbar
            onClose={(event, reason) => {
              // `reason === 'escapeKeyDown'` if `Escape` was pressed
              setOpener(false);
              // call `event.preventDefault` to only close one Snackbar at a time.
            }}
            className={alertclass}
            anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
            open={opener}
            autoHideDuration={4000}
            message={alertmessage}
            key={"bottomright"}
        />
        <Snackbar
            onClose={handleClose}
            className={ualertclass}
            anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
            open={uopen}
            autoHideDuration={4000}
            message={ualertmessage}
            key={"bottomrights"}
        />
        <div className="market-history market-order mt15">
          <Tabs defaultActiveKey="open-orders" onSelect={() => getBots()} >
            <Tab eventKey="open-orders" title={props.messages["exchange.open_orders"]}>
              <Table className="ordertable table-striped">
                <thead>
                <tr className="d-flex justify-content-between market-order-item">
                  <th>{props.messages["exchange.date"]}</th>
                  <th>{props.messages["exchange.pair"]}</th>
                  <th>Order Amount</th>
                  <th>Order Price</th>
                  <th>{props.messages["exchange.side"]}</th>
                  <th>{props.messages["exchange.filled"]}</th>
                  <th>{props.messages["exchange.total"]}</th>
                  <th>Edit Order</th>
                </tr>
                </thead>
                <tbody>
                {props.myorders.length > 0 &&
                props.myorders.map((order) => (
                    <tr key={order._id} className="d-flex justify-content-between market-order-item">
                      <td className="datetd">{order.date}</td>
                      <td>{order.symbol.replace("_","/")}</td>
                      <td>{order.amount}</td>
                      <td>{order.price.toFixed(8)}</td>
                      <td><Button className={"btn-sm btn-"+order.side}>{order.side}</Button></td>
                      <td>{order.status}</td>
                      <td>{order.total}</td>
                      <td> <button onClick={() => handleDelete(order)} className={"btn btnsil"}>İptal Et</button> </td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="closed-orders" title={props.messages["exchange.order_history"]}>
              <Table className="ordertable table-striped">
                <thead>
                <tr className="d-flex justify-content-between market-order-item">
                  <th>{props.messages["exchange.date"]}</th>
                  <th>{props.messages["exchange.pair"]}</th>
                  <th>Miktar</th>
                  <th>Fiyat</th>
                  <th>{props.messages["exchange.side"]}</th>
                  <th>Durum</th>
                  <th>{props.messages["exchange.total"]}</th>

                </tr>
                </thead>
                <tbody>
                {props.mygecmis.length > 0 &&
                props.mygecmis.map((order) => (
                    <tr key={order._id} className="d-flex justify-content-between market-order-item">
                      <td className="datetd">{order.date}</td>
                      <td>{order.symbol.replace("_","/")}</td>
                      <td>{order.amount}</td>
                      <td>{order.price}</td>
                      <td><Button className={"btn-sm btn-"+order.side}>{order.side}</Button></td>
                      <td>{order.status}</td>
                      <td>{order.total}</td>

                    </tr>
                ))}
                </tbody>
              </Table>
            </Tab>

            <Tab eventKey="balance" title={props.messages["exchange.botorder_sellbuy"]}>

              <div className="mb-2 ml-3 mt-3 botstatus row">
                <div className="col-md-4">
                  <b className={"mr-2"}>Bot Durumu :</b>
                  {userid ?
                      <>
                        <button className={"botsroguvar mr-3 btn btn-sm btn-"+botsatal.status}> <i className="icon ion-md-cog" /> {botsatal.title}</button>
                        <button onClick={() => handleSellBotsorgu(botsatal)} className={"btn-sm btn btn-"+botsatal.actionstatus}><i className={"icon ion-md-"+botsatal.actionicon} /> {botsatal.action}</button>
                      </>
                      :
                      <>
                        <b>Görmek İçin Giriş Yapın</b>

                      </>
                  }
                </div>

              </div>
              <Table className="ordertable table-striped">
                <thead>
                <tr className="d-flex justify-content-between market-order-item">
                  <th>{props.messages["exchange.date"]}</th>
                  <th>{props.messages["exchange.pair"]}</th>
                  <th>Alış Fiyatı</th>
                  <th>Satış Fiyatı</th>
                  <th>Miktar</th>
                  <th>Durum</th>
                  <th>Anlık Durum</th>
                  <th>Düzenle / Sil</th>
                </tr>
                </thead>
                <tbody>
                {sborders ? (
                    sborders.map((order) => (
                        <tr key={order._id} className="d-flex justify-content-between market-order-item">
                          <td className="datetd">{order.date}</td>
                          <td>{order.symbol.replace("_","/")}</td>

                          <td>{order.buyprice}</td>
                          <td>{order.sellprice}</td>
                          <td>{order.amount}</td>
                          <td><Button className={"btn-sm btn-"+order.status}>{order.status==='NEW'? 'ÇALIŞIYOR':'DURDU'}
                            {order.errors ?  <Tooltip className="tooltips" title={order.errors}><div className="tooltip-data">?</div></Tooltip> :''}
                          </Button></td>
                          {order.side==='BUY' ?
                              <td><Button className={"w100 btn-sm btn-success"}>Satışları Bekliyor</Button></td>
                              :
                              <td><Button className={"w100 btn-sm btn-danger"}>Alışları Bekliyor</Button></td>

                          }
                          <td>
                            {order.status==='NEW' ?
                                <button onClick={() => { handleUpdatesell({status:"CANCELED"},"orders", {_id:order._id},"sellbuycancel") }} className={"mr5 btn-sm btn-warning"}>Durdur </button>
                                :
                                <Button onClick={() => handleUpdatesell({status:"NEW"},"orders", {_id:order._id},"sellbuycancel")} className={"mr5 btn-sm btn-success"}>Başlat</Button>
                            }
                            <button onClick={() => handleDeletes(order)} className={"btn silbtn btnsil btn-danger"}>Sil</button>

                          </td>
                        </tr>
                    ))):(<></>)}
                </tbody>
              </Table>


            </Tab>
          </Tabs>
        </div>
      </>
  );
}
