import React, {useEffect} from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { ThemeConsumer } from '../context/ThemeContext';
import Header from "../components/Header";
import {connect, useDispatch, useSelector} from "react-redux";
import jwtDecode from "jwt-decode";
import Bank from '../components/Bank'
import {fetchRows,addnewData} from "../actions/general";
import {Button, DatePicker, Form, Input} from "antd";
const Bankaodeme = () => {
    const [forms, setForms] = React.useState({
        'name' : null,
        'iban' : null,
        'date' : null,
        'bank' : null,
        'amount' : null
    });
    function getuserlocal() {    if(localStorage.getItem("gsdhhrxchbhlkpkakd"))
    {    return jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd")).id    }
    else {     return null;    }  }
    const dispatch = useDispatch();
    const userid= getuserlocal();
    const bankssorgu= { limit: '0', "query": {"status": "ACTIVE"} }
    const userbankssorgu= { limit: '0', "query": {user: userid} }
    useEffect(() => {
        dispatch(fetchRows("banks",bankssorgu,"banks"));
        dispatch(fetchRows("bankpayments",userbankssorgu,"bankpayments"));
    }, []);
const allData = useSelector((state) => state.general);
const banks = allData.extradata.banks;
const bankpayments =  allData.extradata.bankpayments;

    const onFinish = values => {
    console.log(values);
    values.status="BEKLEMEDE";
    values.user=userid;

        dispatch(addnewData("bankpayments",values)).then(()=>{
           window.location.reload();
        });


    };
    return (
        <>
            <Header/>
            <div className="settings mtb15">
                <div className="container">
                    <Tab.Container defaultActiveKey="bankayatirma">
                        <Row>
                            <Col lg={3}>
                                <Nav variant="pills" className="settings-nav">

                                    <Nav.Item>
                                        <Nav.Link eventKey="bankayatirma">Banka Yatırma</Nav.Link>
                                        <Nav.Link eventKey="bankacekme">Banka Çekme</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col lg={9}>
                                <Tab.Content>

                                    <Tab.Pane eventKey="bankayatirma">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Banka Para Yatırma Bilgisi</h5>
                                            </div>
                                            <div className={"banks"}>
                                                <h4>Banka Hesap Bilgilerimiz</h4>
                                                {banks ?
                                                banks.map((bank) => (
                                                    <Bank bank={bank}/>
                                                )):(<></>)}
                                            </div>

                                        </div>
                                        <div className="card settings-profile">
                                            <div className="card-body">
                                                <h5 className="card-title">Ödeme Detaylarınız</h5>

                                                <Form
                                                    labelCol={{ span: 0 }}
                                                    wrapperCol={{ span: 24 }}
                                                    layout="horizontal"
                                                    onFinish={onFinish}
                                                >


                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <Form.Item  label="Hesap Sahibi Ad Soyad"  name="name"  rules={[{ required: true, message:"Bu alan zorunludur" }]}>
                                                            <Input    className="form-control"  />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Item  label="IBAN"  name="iban"  rules={[{ required: true, message:"Bu alan zorunludur" }]}>
                                                            <Input    className="form-control"  />
                                                        </Form.Item>


                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Item  label="Ödeme Tarihi"  name="date"  rules={[{ required: true, message:"Bu alan zorunludur" }]}>
                                                            <Input type="date"    className="form-control"  />
                                                        </Form.Item>


                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Item  label="Banka"  name="bank"  rules={[{ required: true, message:"Bu alan zorunludur" }]}>
                                                            <Input    className="form-control"  />
                                                        </Form.Item>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Item  label="Ödeme Tutarı"  name="amount"  rules={[{ required: true, message:"Bu alan zorunludur" }]}>
                                                            <Input    className="form-control"  />
                                                        </Form.Item>

                                                    </div>
                                                    <div className="col-md-12">
                                                        <Form.Item>
                                                            <Button className={"btn btn-lg btn-primary"} type="primary" htmlType="submit">
                                                                Ödeme Bilgisi Gönder
                                                            </Button>
                                                        </Form.Item>

                                                    </div>
                                                </div>
                                                </Form>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Geçmiş Yatırmalar</h5>
                                                <div className="wallet-history">
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th>No.</th>
                                                            <th>Banka</th>
                                                            <th>Miktar</th>
                                                            <th>Durum</th>
                                                            <th>Tarih</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {bankpayments ?
                                                            bankpayments.map((bank) => (

                                                        <tr>
                                                            <td>1</td>
                                                            <td>{bank.bank}</td>
                                                            <td>{bank.amount} TRY</td>
                                                            <td>
                                                                <div className="custom-control custom-switch">
                                                                    {bank.status}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {bank.date}
                                                            </td>
                                                        </tr>
                                                            )):(<></>)}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ theme }) => {
    return theme;
};
export default connect(mapStateToProps, {  })(Bankaodeme);
