import React from 'react';
import { render } from 'react-dom';
import Chart from './Chart';
import { getData } from "./utils"
import _ from "lodash";
import {WEB_CONFIG} from "../config/Static";
import axios from "axios";
class TradeChart extends React.Component {

    componentDidMount() {
        axios.get(WEB_CONFIG.server+`/api/general/getorderdata`)
            .then((res) => {
                let values = res.data
                let data = values.map((obj) => {
                    let date = obj.date + 'T05:00:00.000Z'
                    obj.date = new Date(date)
                    return obj
                })
                this.setState({ data })

            });
    }
    render() {
        //console.log(this.state);
        if (this.state == null) {
            return <div>Loading...</div>
        }
        return (
            <Chart  data={this.state.data} />

        )
    }
}

export default TradeChart;